import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import {Toolbar} from "../../../smartDongles/views/components";
import {SolInput} from "../../../../components/SolStyledComponents/components";
import {DataTableContainer} from "../../../rentLog/utils";
import {LoadingSpinner} from '../../../../components/LoadingSpinnerForDataTable';
import DataTable from "../../../../components/dataTable/DataTable";
import {connect} from "react-redux";
import {appEventActions} from "../../../appEvents/actions";
import {actions} from "../../actions";
import DateTimeFormat from "../../../../utils/dateFormat/DateTimeFormat";
import {Button, Spinner} from "react-bootstrap";
import {getServiceName} from "../../../../utils/roleRelatedValues/serviceNames";
import {getFeatureName} from "../../../../utils/roleRelatedValues/featureNames";
import {getActionName} from "../../../../utils/roleRelatedValues/actionNames";
import {checkAuthorization} from "../../../auth/authorization";
import {toBengaliNumber} from "bengali-number";
import {useSubheader} from "../../../../../_metronic/layout";
import moment from "moment";
import {Form} from "react-bootstrap";
import Select from 'react-select';
import {Controller, useForm} from "react-hook-form";
import { parseParams} from "../../../../utils/utilityFunctions";
import {toast} from "react-toastify";

const FirmwareList = props => {

    const validFirmwareTypes = [
        "firmware",
        "bootloader",
        "test_firmware",
        "initial_firmware",
    ]
    const firmwareTypes = [
        { value: validFirmwareTypes[0], label: "Operation Firmware" },
        { value: validFirmwareTypes[1], label: "Bootloader Firmware" },
        { value: validFirmwareTypes[2], label: "Test Firmware" },
        { value: validFirmwareTypes[3], label: "Initial Firmware" },
    ]
    const subHeader = useSubheader()
    const {register, errors, control, clearErrors, handleSubmit, reset, setValue, setError} = useForm()
    const [authorization, setAuthorization] = useState(null)
    const [language, setLanguage] = useState(null)
    const [filterApplied, setFilterApplied] = useState(false)
    const [page, setPage] = useState(1)
    const [queryParams, setQueryParams] = useState(null)
    const [initialfilterParam, setInitialfilterParam] = useState(null)
    const [filterParamForApiCall, setFilterParamForApiCall] = useState(null)
    const [urlIsEdited, setUrlIsEdited] = useState(false)
    const [deviceServiceName, setDeviceServiceName] = useState(getServiceName('deviceService'));
    const [firmwareFeatureName, setFirmwareFeatureName] = useState(getFeatureName('firmwareFeature'));
    const [createActionName, setCreateActionName] = useState(getActionName('createAction'));
    const [updateActionName, setUpdateActionName] = useState(getActionName('updateAction'));


    const FirmwareListColumns = [
        {
            field: 'sl',
            title: language === 'EN'? 'Sl':'ক্রম',
            emptyValue: ()=>{
                return "N/A"
            },
            width: "5",
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            }
            // render: rowData => (rowData.app_event_time? new DateTimeFormat(rowData.app_event_time).displayTime : "--")
        },
        {
            field: 'version',
            title: language === 'EN'? 'Version':'ফার্মওয়্যারের সংস্করণ',
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            }
            // disableClick: true,
        },
        {
            field: 'hardware_version_list',
            title: language === 'EN'? 'Supported Hardware Versions':'ফার্মওয়্যার যেসব হার্ডওয়্যার সংস্করণকে সমর্থন করে',
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: rowData => (rowData.hardware_version_list? rowData.hardware_version_list.toString() : "--")
        },
        {
            field: 'sk',
            title: language === 'EN'? 'Creation Date':'মুক্তির তারিখ',
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: rowData => (rowData.sk? moment(rowData.sk).add(6, 'hours').format("DD MMM,YYYY hh:mm A"): "--")
        },
        // {
        //     field: 'staging_release_date',
        //     title: language === 'EN'? 'Staging Release Date':'স্টেজিং থেকে মুক্তির তারিখ',
        //     emptyValue: ()=>{
        //         return "N/A"
        //     },
        //     render: rowData => (rowData.staging_release_date? new DateTimeFormat(rowData.staging_release_date).displayTime : "--")
        // },
        {
            field: 'status',
            title: language === 'EN'? 'Status':'বর্তমান অবস্থা',
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                textTransform: 'capitalize',
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            }
        },
        {
            field: 'created_by_name',
            title: language === 'EN'? 'Created by':'ফার্মওয়্যার তৈরি হয়েছে যার দ্বারা',
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            }
        },
    ]

    const {
        GET_FIRMWARE_LIST,
        RETURN_TO_INITIAL,
        firmwares,
    } = props

    useEffect(()=>{
        setAuthorization(props.authorization)
        setLanguage(props.language)
        if (checkAuthorization(props.authorization, deviceServiceName, firmwareFeatureName, [createActionName])) {
            subHeader.setActionButtons(
                <div className="d-flex align-item-center justify-content-end">
                    <Button variant={"warning"} size={"sm"}
                            onClick={() => {goToCreateFirmware()}}>
                        <i className="fa fa-plus"/>{props.language === 'EN'? 'Create Firmware':'ফার্মওয়্যার তৈরি করুন'}
                    </Button>
                </div>
            )
        } else {
            subHeader.setActionButtons(null)
        }
        subHeader.setBreadCrumbComponent(null)

        if (!props.history.location.search) {
            setValue('firmware_type', firmwareTypes[0])
            // setPage(1)
            props.history.push({
                search: 'firmware_type=' + firmwareTypes[0].value
            })
        }
        return ()=>{
            RETURN_TO_INITIAL()
        }
    },[])

    // ----- Begin: Section related to shareable link/handling query params ---- //
    // const validationKeyArray = ['page', 'firmware_type']
    const validationKeyArray = ['firmware_type']

    useEffect(() => {
        let temp = null;
        try {
            temp = parseParams(props.history?.location?.search);
            if (Object.keys(temp)?.length > 0) {
                /* Set the projected filter lists. Requires validation */
                setQueryParams(temp);
            }
        } catch (e) {
            console.debug(e);
        }
    },[props.history.location.search])

    useEffect(() => {
        let keys = null;
        const validParameters = {};
        /* Only these keys in the projected params are valid in the projected params */

        /* Getting the keys in the projected params */
        try {
            keys = Object.keys(queryParams);
        } catch (e) {
            console.log(e)
            return;
        }
        /* Checking for if keys value is an array and has at least one value */
        if (Array.isArray(keys) && keys.length > 0) {
            /* Looping through each of the value in the keys. (Essentially This loop will run for every key in the projected params object) */
            keys.forEach((item) => {
                /* We are checking if the key is valid by checking against validationKeyArray. if it matches we include it in the validParams object */
                const itemExists = validationKeyArray.includes(item)
                if (itemExists) {
                    Object.assign(validParameters, {[`${item}`]: queryParams[item]})
                }
            })
        }
        setInitialfilterParam(Object.keys(validParameters?.length > 0) ? {...validParameters} : null)
        setFilterParamForApiCall(Object.keys(validParameters?.length > 0) ? {...validParameters} : null)
    }, [queryParams])

    useEffect(() => {
        if (initialfilterParam) {
            setUrlIsEdited(true)
            // if (initialfilterParam.page) {
            //     let page = initialfilterParam.page
            //     if (/^(0|[1-9][0-9]*)$/i.test(page)) {
            //         setPage(parseInt(page))
            //     } else {
            //         toast.error("Please provide only number as page count! Collecting data for first page..")
            //     }
            // }
            if (initialfilterParam.firmware_type) {
                let firmwareTypeFromUrl = initialfilterParam.firmware_type
                let selectedFirmware = firmwareTypes.filter(item => item.value ===  firmwareTypeFromUrl)
                setValue('firmware_type', selectedFirmware[0]? selectedFirmware[0]:'')
            }
            setInitialfilterParam(null)
        }
    }, [initialfilterParam])

    useEffect(() => {
        if (urlIsEdited && Object.keys(errors).length === 0) {
            // Page will always be there
            // let filter = {page: page}
            let filter = {}
            let filterApplied = false
            if (filterParamForApiCall.firmware_type) {
                filter['firmware_type'] = filterParamForApiCall.firmware_type
                filterApplied = true
            }
            setFilterApplied(filterApplied)
            GET_FIRMWARE_LIST({...filter})
            setUrlIsEdited(false)
        }
    }, [urlIsEdited]);
    // ----- End: Section related to shareable link/handling query params ---- //

    const goToCreateFirmware = () => {
        props.history.push({
            pathname: '/ota/firmwares/new',
            purpose: 'create'
        })
    }

    const goToUpdateFirmware = (rowData) => {
        props.history.push({
            pathname: '/ota/firmwares/edit',
            purpose: 'edit',
            firmwareData: rowData
        })
    }

    const filterTheList = (data) => {
        // setPage(1)
        // let searchText = 'page=1'
        let searchText = ''
        if (data.firmware_type) {
            searchText += '&firmware_type=' + data.firmware_type.value
        }
        props.history.push({
            search: searchText
        })
    }

    const resetFilter = () => {
        setValue('firmware_type', '')
        clearErrors()
        setFilterApplied(false)
        if (filterApplied) {
            // Resetting the firmware list
            // setPage(1)
            props.history.push({
                // search: 'page=1'
                search: ''
            })
        }
    }

    const onChangePage = (newPage) => {
        setPage(newPage)
        let searchText = 'page=' + newPage
        if (filterApplied) {
            if (filterParamForApiCall.firmware_type) {
                searchText += '&firmware_type=' + filterParamForApiCall.firmware_type
            }
        }
        props.history.push({
            search: searchText
        })
    }

    return (
        <ContentWrapper isLoading={false} pageTitle={"Firmware List"} showBackButton={false} showCardHeader={false}>
            <div>
                <Toolbar>
                    <Toolbar.Title>
                        <h1><b>{language === 'EN'? 'Firmware List':'ফার্মওয়্যারের তালিকা'}</b></h1>
                    </Toolbar.Title>
                    <Toolbar.ToolbarContainer>
                        <Toolbar.ToolbarContainer.Items>
                            {/*<div className={'col-md-4'}>*/}
                            {/*    <SolInput*/}
                            {/*        type={'text'}*/}
                            {/*        placeholder={'Device Serial Number'}*/}
                            {/*        onKeyPress={searchDevice}*/}
                            {/*        onChange={(event) => {*/}
                            {/*            if (event.target.value === '') {*/}
                            {/*                onSearch(event);*/}
                            {/*            }*/}
                            {/*        }}*/}
                            {/*    />*/}
                            {/*</div>*/}
                        </Toolbar.ToolbarContainer.Items>
                    </Toolbar.ToolbarContainer>
                </Toolbar>
                <hr/>
            </div>

            <div >
                <>
                    <div className={'row'}>
                        <div className={'col-md-12'}>
                            <Form onSubmit={handleSubmit(filterTheList)}>
                                <div className={'row g-3'}>
                                    <div className={'col-md-4'}>
                                        <Form.Group>
                                            <div>
                                                <Form.Label>{language === 'EN' ? 'Firmware type' : 'সরবরাহকারী'}</Form.Label>
                                            </div>
                                            <Controller
                                                control={control}
                                                name={"firmware_type"}
                                                render={( { onChange, onBlur, value, name, ref },
                                                          { invalid, isTouched, isDirty }) => (
                                                    <Select
                                                        placeholder={language === 'EN' ? 'Select a Firmware type' : 'বিএমএস বিক্রেতা বাছাই করুন'}
                                                        classNamePrefix="react-select-sol-style"
                                                        isDisabled={props.listLoading}
                                                        maxMenuHeight={200}
                                                        value={value}
                                                        isClearable={true}
                                                        control={control}
                                                        inputRef={ref}
                                                        options={firmwareTypes}
                                                        isSearchable={true}
                                                        onChange={(selected, {action}) => {
                                                            onChange(selected)
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Form.Group>
                                    </div>
                                </div>

                                <div className={'row'}>
                                    <div className={"col-md-12"}>
                                        <Button variant="warning" size={'sm'} type="submit"
                                                disabled={props.listLoading}>
                                            {props.listLoading? <><Spinner animation={'border'} size={'sm'}
                                                                           variant={'light'}/></>:null}
                                            {language === 'EN'? 'Apply filter':'তালিকায় খুঁজুন'}
                                        </Button>
                                        <Button variant="outline-dark" size={'sm'}
                                                onClick={()=> {resetFilter()}}
                                                className={'ml-3'}>
                                            {language === 'EN'? 'Reset filter':'প্রাথমিক অবস্থায় ফিরে যান'}
                                        </Button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                    <hr /></>
            </div>
            <div>
                <DataTableContainer>
                    {props.listLoading !== true ? <DataTable
                        language={language}
                        noDataAvailableMessageInBangla={'কোন ফার্মওয়্যার নেই'}
                        columns={FirmwareListColumns}
                        data={Array.isArray(firmwares)?firmwares.map((item,index)=>({...item, sl: language === 'EN'? index+1:toBengaliNumber(index+1)})):[]}
                        // asyncPagination={true}
                        // count={firmwares?.count}
                        // itemsPerPage={firmwares?.page_size}
                        // onChangePage={onChangePage}
                        // page={page}
                        asyncPagination={false}
                        pagination={true}
                        pageSize={10}
                        actionColumnIndex={-1}
                        actions={checkAuthorization(authorization, deviceServiceName, firmwareFeatureName, [updateActionName])? [{}]:[]}
                        actionButtonVariant={['warning']}
                        actionButtonSize={'sm'}
                        actionButtonClickEvent={[goToUpdateFirmware]}
                        actionButtonText={language === 'EN'? ['Update']:['সম্পাদনা করুন']}
                    /> : <LoadingSpinner loadingSubText={language === 'EN'? 'Generating firmware list ..': 'ফার্মওয়্যারের তালিকা প্রস্তুত হচ্ছে'} language={language}/>}
                </DataTableContainer>
            </div>
        </ContentWrapper>
    );
};


export default connect((state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        isLoading: state.otaReducer.isLoading,
        firmwares: state.otaReducer.firmwares,
        listLoading: state.otaReducer.listLoading,
    }
}, {...actions}) (FirmwareList);
