import React, {Fragment, useEffect, useState} from 'react';
// import PropTypes from 'prop-types';
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import DataTable from "../../../../components/dataTable/DataTable";
import styled from "styled-components";
import {Filter, Toolbar} from "../components";
import FormContainer from "../components";
import {connect} from "react-redux";
import {actions} from "../../actions";
import {Breadcrumb, Button, Form, OverlayTrigger, Spinner, Dropdown, DropdownButton} from "react-bootstrap";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import {CloudDownloadOutlined} from "@material-ui/icons";
import {SolInput} from "../../../../components/SolStyledComponents/components";
import {makeStyles} from "@material-ui/styles";
import {DataTableContainer, getLoadingSpinner} from '../../utils';
import {LoadingSpinner} from '../../../../components/LoadingSpinnerForDataTable';
import {Link} from "react-router-dom";
import {useSubheader} from "../../../../../_metronic/layout";
import {isNumber, JsonToUriSerialize, parseParams} from "../../../../utils/utilityFunctions";
import {withRoles} from "../../../../router/SecuredRoute";
import Select from "react-select";
import {isValidPhoneNumber} from "libphonenumber-js";
import {getServiceName} from "../../../../utils/roleRelatedValues/serviceNames";
import {getFeatureName} from "../../../../utils/roleRelatedValues/featureNames";
import {getActionName} from "../../../../utils/roleRelatedValues/actionNames";
import moment from "moment";
import {checkAuthorization} from "../../../auth/authorization";
import {Controller, useForm} from "react-hook-form";
import SolRadioInput from "../../../../components/solradioinput/SolRadioInput";
import {toast} from "react-toastify";
import {showNotifications} from "../../../../utils/notification";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import {useMediaQuery} from "react-responsive";
import SettingsIcon from "@mui/icons-material/Settings";
import UserSettings from "./userSettings"

const useStylesIconButton = makeStyles({
    root: {
        marginTop: '0',
        paddingTop: '4px',
    },
});

const NewUserList = (props) => {
    const {register, errors, control, clearErrors, handleSubmit, reset, setValue, setError} = useForm();

    // Filter related
    const [garages, setGarages] = useState([]);
    const [designations, setDesignations] = useState([]);
    const [garageUsernames, setGarageUsernames] = useState([]);
    const [divisions, setDivisions] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [subDistricts, setSubDistricts] = useState([]);
    const [financiers, setFinanciers] = useState([]);
    const [branches, setBranches] = useState([]);
    const [showFilter, setShowFilter] = useState(true);
    const [filterData, setFilterData] = useState(null);

    const [totalUsers, setTotalUsers] = useState(25);
    const [userList, setUserList] = useState([{
        'user_id': '12189',
        'name': 'Sabbir Ahmed',
        'user_type': 'Driver',
        'user_type_value': 'garage_based_user',
        'username': '01718489198',
        'organization_name': 'ABC Corporation updated',
        'organization_guid': '431fa822-ca14-464b-9f79-03e27e0bd7db',
        'branch_guid': 'dfadf29f-cc6d-4e3a-963d-c24be8c45287',
        'organization_type': 'Garage',
        'organization_address': 'Rangamati Sadar, Rangamati',
        'registered_at': 'Oct 12, 2023',
        'role': 'Admin',
        'role_guid': '2d13c8e6-65d6-4089-a70f-bdf757cbb632',
        'total_paid': '3,500',
        'overdue': '500',
        'balance': '500'
    }, {
        'user_id': '12190',
        'name': 'Anika Tabassum',
        'user_type': 'Garage Owner',
        'user_type_value': 'garage_based_user',
        'username': '01718489199',
        'organization_name': 'Fairway Trade Link',
        'organization_type': 'Garage',
        'organization_guid': '002d00f4-1ab1-4116-9cea-29ebda6f70ee',
        'branch_guid': '431a79b4-e915-4298-96fe-2a5210779287',
        'organization_address': 'Savar, Dhaka',
        'registered_at': 'Oct 13, 2023',
        'role': 'Mpower',
        'role_guid': 'aa29422c-c035-4f72-8904-ccd0513749f2',
        'total_paid': '3,500',
        'overdue': '500',
        'balance': '500'
    }, {
        'user_id': '12191',
        'name': 'Faruk Ahmed',
        'user_type': 'Field Agent',
        'user_type_value': 'non_garage_based_user',
        'username': '01718489200',
        'organization_name': 'EFG Capital Solutions',
        'organization_type': 'Financier',
        'organization_guid': '09948e92-fe35-4749-b87c-891007afa3cb',
        'branch_guid': '5aa0d1de-1674-4c3a-af3d-e873d088daaa',
        'organization_address': 'Joar Sahara, DNCC, Dhaka',
        'registered_at': 'Oct 14, 2023',
        'role': 'Operation',
        'role_guid': 'c1ce7b0b-8c60-480e-b07a-01d1d421705d',
        'total_paid': null,
        'overdue': null,
        'balance': null
    }, {
        'user_id': '12190',
        'name': 'Anika Tabassum',
        'user_type': 'Garage Owner',
        'user_type_value': 'garage_based_user',
        'username': '01718489199',
        'organization_name': 'Fairway Trade Link',
        'organization_type': 'Garage',
        'organization_guid': '002d00f4-1ab1-4116-9cea-29ebda6f70ee',
        'branch_guid': '431a79b4-e915-4298-96fe-2a5210779287',
        'organization_address': 'Savar, Dhaka',
        'registered_at': 'Oct 13, 2023',
        'role': 'Operation',
        'role_guid': 'c1ce7b0b-8c60-480e-b07a-01d1d421705d',
        'total_paid': '3,500',
        'overdue': '500',
        'balance': '500'
    }, {
        'user_id': '12191',
        'name': 'Faruk Ahmed',
        'user_type': 'Field Agent',
        'user_type_value': 'non_garage_based_user',
        'username': '01718489200',
        'organization_name': 'EFG Capital Solutions',
        'organization_type': 'Financier',
        'organization_guid': '09948e92-fe35-4749-b87c-891007afa3cb',
        'branch_guid': '5aa0d1de-1674-4c3a-af3d-e873d088daaa',
        'organization_address': 'Joar Sahara, DNCC, Dhaka',
        'registered_at': 'Oct 14, 2023',
        'role': 'Operation',
        'role_guid': 'c1ce7b0b-8c60-480e-b07a-01d1d421705d',
        'total_paid': null,
        'overdue': null,
        'balance': null
    }]);

    // URL (query) param
    const [page, setPage] = useState(1);
    const [filterApplied, setFilterApplied] = useState(false);
    const [queryParams, setQueryParams] = useState(null)
    const [initialfilterParam, setInitialfilterParam] = useState(null)
    const [filterParamForApiCall, setFilterParamForApiCall] = useState(null)
    const [urlIsEdited, setUrlIsEdited] = useState(false)

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const subHeader = useSubheader();

    const [authorization, setAuthorization] = useState(null);
    const [language, setLanguage] = useState(null);
    const [userServiceName, setUserServiceName] = useState(getServiceName('userService'));
    const [userFeatureName, setUserFeatureName] = useState(getFeatureName('userFeature'));
    const [createActionName, setCreateActionName] = useState(getActionName('createAction'));
    const [updateActionName, setUpdateActionName] = useState(getActionName('updateAction'));

    const userListColumns = [
        {
            field: 'user_id',
            title: language === 'EN'? 'User ID':'আইডি',
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            // disableClick: true,
        },
        {
            field: 'name',
            title: language === 'EN'? 'Name':'নাম',
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData)=>{
                return   <>
                    <span><strong>{rowData.name}</strong></span><br/>
                    <span><small>{rowData.user_type}</small></span><br/>
                    <span><small>{rowData.username}</small></span>
                </>
            }
        },
        {
            field: 'organization_name',
            title: 'Organization / Garage',
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData)=>{
                return   <>
                    <span>{rowData.organization_name}</span><br/>
                    <span>{rowData.organization_type}</span><br/>
                    <span>{rowData.organization_address}</span>
                </>
            }
        },
        {
            field: 'registered_at',
            title: 'Registered At',
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            }
            // disableClick: true,
        },
        {
            field: 'role',
            title: 'Role',
            emptyValue: ()=> {
                return "-"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            }
        },
        {
            field: 'total_paid',
            title: 'Total Paid',
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            }
        },
        {
            field: 'overdue',
            title: 'Total Overdue',
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: (rowData)=>{
                return   <>
                    <h6><strong style={{color: '#cb152b'}}>{rowData.overdue}</strong></h6>
                </>
            }
        },
        {
            field: 'balance',
            title: 'Balance',
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            }
        }
    ]

    const {
        GET_USER_LIST,
        RETURN_TO_INITIAL_USER_REDUCER_STATE
    } = props;
    const classes = useStylesIconButton();

    useEffect(() => {
        setAuthorization(props.authorization)
        setLanguage(props.language)
        if (checkAuthorization(props.authorization, userServiceName, userFeatureName, [createActionName])) {
            subHeader.setActionButtons(
                <div className="d-flex align-item-center justify-content-end">
                    <Button variant="warning" type="submit" style={{
                        backgroundColor: '#F18D00 !important',
                    }} onClick={gotToCreateuser} classes={classes} >
                        {/*<FilterListIcon color="disabled" fontSize="large"/>*/}
                        {props.language === 'EN'? <i className="fa fa-user-plus"/>:null}
                        {props.language === 'EN'? 'Register User':'ব্যবহারকারী নিবন্ধন করুন'}
                    </Button>
                </div>
            )
        } else {
            subHeader.setActionButtons(null)
        }
        subHeader.setBreadCrumbComponent(null)
        if (!props.history.location.search) {
            setPage(1)
            props.history.push({
                search: 'page=1'
            })
        }
        return () => {
            RETURN_TO_INITIAL_USER_REDUCER_STATE()
        }
    }, []);

    // ----- Begin: Section related to shareable link/handling query params ---- //
    const validationKeyArray = ['page', 'user_id', 'username']

    useEffect(() => {
        let temp = null;
        try {
            temp = parseParams(props.history?.location?.search);
            if (Object.keys(temp)?.length > 0) {
                /* Set the projected filter lists. Requires validation */
                setQueryParams(temp);
            }
        } catch (e) {
            console.debug(e);
        }
    },[props.history.location.search])

    useEffect(() => {
        let keys = null;
        const validParameters = {};
        /* Only these keys in the projected params are valid in the projected params */

        /* Getting the keys in the projected params */
        try {
            keys = Object.keys(queryParams);
        } catch (e) {
            console.log(e)
            return;
        }
        /* Checking for if keys value is an array and has at least one value */
        if (Array.isArray(keys) && keys.length > 0) {
            /* Looping through each of the value in the keys. (Essentially This loop will run for every key in the projected params object) */
            keys.forEach((item) => {
                /* We are checking if the key is valid by checking against validationKeyArray. if it matches we include it in the validParams object */
                const itemExists = validationKeyArray.includes(item)
                if (itemExists) {
                    Object.assign(validParameters, {[`${item}`]: queryParams[item]})
                }
            })
        }
        setInitialfilterParam(Object.keys(validParameters?.length > 0) ? {...validParameters} : null)
        setFilterParamForApiCall(Object.keys(validParameters?.length > 0) ? {...validParameters} : null)
    }, [queryParams])

    useEffect(() => {
        if (initialfilterParam) {
            setUrlIsEdited(true)
            if (initialfilterParam.page) {
                let page = initialfilterParam.page
                if (/^(0|[1-9][0-9]*)$/i.test(page)) {
                    setPage(parseInt(page))
                } else {
                    toast.error("Please provide only number as page count! Collecting data for first page..")
                }
            }
            if (initialfilterParam.user_id) {
                let userIdFromUrl = initialfilterParam.user_id
                setValue('user_id', userIdFromUrl)
            }
            if (initialfilterParam.username) {
                let usernameFromUrl = initialfilterParam.username
                setValue('username', usernameFromUrl)
            }
            setInitialfilterParam(null)
        }
    }, [initialfilterParam])

    useEffect(() => {
        if (urlIsEdited && Object.keys(errors).length === 0) {
            // Page will always be there
            let filter = {page: page}
            let filterApplied = false
            if (filterParamForApiCall.user_id) {
                filter['user_id'] = filterParamForApiCall.user_id
                filterApplied = true
            }
            if (filterParamForApiCall.username) {
                filter['username'] = filterParamForApiCall.username
                filterApplied = true
            }
            setFilterApplied(filterApplied)
            // GET_USER_LIST({...filter})
            setUrlIsEdited(false)
        }
    }, [urlIsEdited]);
    // ----- End: Section related to shareable link/handling query params ---- //

    // ----- Begin: Filter form design and event related ---- //
    const resetFilter = () => {
        if (filterApplied) {
            // Resetting the user list
            setPage(1)
            props.history.push({
                search: 'page=1'
            })
        }
        resetFilterValues()
        setFilterApplied(false)
        clearErrors()
    }

    const resetFilterValues = () => {
        setValue('user_id', '')
        setValue('username', '')
    }

    const filterTheList = (data) => {
        setPage(1)
        let searchText = 'page=1'
        if (data.user_id) {
            searchText += '&user_id=' + data.user_id
        }
        if (data.username) {
            searchText += '&username=' + data.username
        }
        props.history.push({
            search: searchText
        })
    }

    const filterToggle = () => {
        setShowFilter(!showFilter)
    }

    const filterForm = () => {
        return <>
            <div className={'row'}>
                <div className={'col-md-12'}>
                    <Form onSubmit={handleSubmit(filterTheList)}>
                        <div className={'row g-3'}>
                            <div className={'col-md-2'}>
                                <Form.Group>
                                    <div>
                                        <Form.Label>Mobile Number</Form.Label>
                                    </div>
                                    {/* TODO: We may need to update the name attribute from 'username' */}
                                    <SolInput
                                        type={"text"}
                                        name={"username"}
                                        placeholder={"Type mobile number..."}
                                        autoComplete={"off"}
                                        ref={register()}
                                    />
                                    {errors.username && <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.username.message}</div>}
                                </Form.Group>
                            </div>
                            <div className={'col-md-2'}>
                                <Form.Group>
                                    <div>
                                        <Form.Label>User ID</Form.Label>
                                    </div>
                                    <SolInput
                                        type={"number"}
                                        name={"user_id"}
                                        step={"1"}
                                        min={"1"}
                                        placeholder={"Type User ID..."}
                                        autoComplete={"off"}
                                        ref={register()}
                                    />
                                    {errors.user_id && <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.user_id.message}</div>}
                                </Form.Group>
                            </div>
                            <div className={'col-md-2'}>
                                <Form.Group>
                                    <div>
                                        <Form.Label>Garage</Form.Label>
                                    </div>
                                    <Controller
                                        control={control}
                                        name={"garage"}
                                        defaultValue={""}
                                        render={( { onChange, onBlur, value, name, ref },
                                                  { invalid, isTouched, isDirty }) => (
                                            <Select
                                                placeholder={'Select Garage'}
                                                classNamePrefix="react-select-sol-style"
                                                isDisabled={props.collectingGarages}
                                                isLoading={props.collectingGarages}
                                                maxMenuHeight={200}
                                                value={value}
                                                isClearable={true}
                                                control={control}
                                                inputRef={ref}
                                                options={garages}
                                                isSearchable={true}
                                                noOptionsMessage={() => "No garages available"}
                                                onChange={(selected, {action}) => {
                                                    onChange(selected)
                                                }}
                                            />
                                        )}
                                    />
                                </Form.Group>
                            </div>
                            <div className={'col-md-2'}>
                                <Form.Group>
                                    <div>
                                        <Form.Label>Designation</Form.Label>
                                    </div>
                                    <Controller
                                        control={control}
                                        name={"designation"}
                                        defaultValue={""}
                                        render={( { onChange, onBlur, value, name, ref },
                                                  { invalid, isTouched, isDirty }) => (
                                            <Select
                                                placeholder={'Select Designation'}
                                                classNamePrefix="react-select-sol-style"
                                                isDisabled={props.collectingGarages}
                                                isLoading={props.collectingGarages}
                                                maxMenuHeight={200}
                                                value={value}
                                                isClearable={true}
                                                control={control}
                                                inputRef={ref}
                                                options={designations}
                                                isSearchable={true}
                                                noOptionsMessage={() => "No designations available"}
                                                onChange={(selected, {action}) => {
                                                    onChange(selected)
                                                }}
                                            />
                                        )}
                                    />
                                </Form.Group>
                            </div>
                            <div className={'col-md-2'}>
                                <Form.Group>
                                    <div>
                                        <Form.Label>Garage Username</Form.Label>
                                    </div>
                                    <Controller
                                        control={control}
                                        name={"garage_username"}
                                        defaultValue={""}
                                        render={( { onChange, onBlur, value, name, ref },
                                                  { invalid, isTouched, isDirty }) => (
                                            <Select
                                                placeholder={'Select Username'}
                                                classNamePrefix="react-select-sol-style"
                                                isDisabled={props.collectingGarages}
                                                isLoading={props.collectingGarages}
                                                maxMenuHeight={200}
                                                value={value}
                                                isClearable={true}
                                                control={control}
                                                inputRef={ref}
                                                options={garageUsernames}
                                                isSearchable={true}
                                                noOptionsMessage={() => "No usernames available"}
                                                onChange={(selected, {action}) => {
                                                    onChange(selected)
                                                }}
                                            />
                                        )}
                                    />
                                </Form.Group>
                            </div>
                        </div>
                        <div className={'row g-3'}>
                            <div className={'col-md-2'}>
                                <Form.Group>
                                    <div>
                                        <Form.Label>Division</Form.Label>
                                    </div>
                                    <Controller
                                        control={control}
                                        name={"division"}
                                        defaultValue={""}
                                        render={( { onChange, onBlur, value, name, ref },
                                                  { invalid, isTouched, isDirty }) => (
                                            <Select
                                                placeholder={'Select Division'}
                                                classNamePrefix="react-select-sol-style"
                                                isDisabled={props.collectingGarages}
                                                isLoading={props.collectingGarages}
                                                maxMenuHeight={200}
                                                value={value}
                                                isClearable={true}
                                                control={control}
                                                inputRef={ref}
                                                options={divisions}
                                                isSearchable={true}
                                                noOptionsMessage={() => "No divisions available"}
                                                onChange={(selected, {action}) => {
                                                    onChange(selected)
                                                }}
                                            />
                                        )}
                                    />
                                </Form.Group>
                            </div>
                            <div className={'col-md-2'}>
                                <Form.Group>
                                    <div>
                                        <Form.Label>District</Form.Label>
                                    </div>
                                    <Controller
                                        control={control}
                                        name={"district"}
                                        defaultValue={""}
                                        render={( { onChange, onBlur, value, name, ref },
                                                  { invalid, isTouched, isDirty }) => (
                                            <Select
                                                placeholder={'Select District'}
                                                classNamePrefix="react-select-sol-style"
                                                isDisabled={props.collectingGarages}
                                                isLoading={props.collectingGarages}
                                                maxMenuHeight={200}
                                                value={value}
                                                isClearable={true}
                                                control={control}
                                                inputRef={ref}
                                                options={districts}
                                                isSearchable={true}
                                                noOptionsMessage={() => "No districts available"}
                                                onChange={(selected, {action}) => {
                                                    onChange(selected)
                                                }}
                                            />
                                        )}
                                    />
                                </Form.Group>
                            </div>
                            <div className={'col-md-2'}>
                                <Form.Group>
                                    <div>
                                        <Form.Label>Sub district</Form.Label>
                                    </div>
                                    <Controller
                                        control={control}
                                        name={"subdistrict"}
                                        defaultValue={""}
                                        render={( { onChange, onBlur, value, name, ref },
                                                  { invalid, isTouched, isDirty }) => (
                                            <Select
                                                placeholder={'Select Sub district'}
                                                classNamePrefix="react-select-sol-style"
                                                isDisabled={props.collectingGarages}
                                                isLoading={props.collectingGarages}
                                                maxMenuHeight={200}
                                                value={value}
                                                isClearable={true}
                                                control={control}
                                                inputRef={ref}
                                                options={subDistricts}
                                                isSearchable={true}
                                                noOptionsMessage={() => "No sub-districts available"}
                                                onChange={(selected, {action}) => {
                                                    onChange(selected)
                                                }}
                                            />
                                        )}
                                    />
                                </Form.Group>
                            </div>
                            <div className={'col-md-2'}>
                                <Form.Group>
                                    <div>
                                        <Form.Label>Financier</Form.Label>
                                    </div>
                                    <Controller
                                        control={control}
                                        name={"financier"}
                                        defaultValue={""}
                                        render={( { onChange, onBlur, value, name, ref },
                                                  { invalid, isTouched, isDirty }) => (
                                            <Select
                                                placeholder={'Select Financier'}
                                                classNamePrefix="react-select-sol-style"
                                                isDisabled={props.collectingGarages}
                                                isLoading={props.collectingGarages}
                                                maxMenuHeight={200}
                                                value={value}
                                                isClearable={true}
                                                control={control}
                                                inputRef={ref}
                                                options={financiers}
                                                isSearchable={true}
                                                noOptionsMessage={() => "No financiers available"}
                                                onChange={(selected, {action}) => {
                                                    onChange(selected)
                                                }}
                                            />
                                        )}
                                    />
                                </Form.Group>
                            </div>
                            <div className={'col-md-2'}>
                                <Form.Group>
                                    <div>
                                        <Form.Label>Branch</Form.Label>
                                    </div>
                                    <Controller
                                        control={control}
                                        name={"branch"}
                                        defaultValue={""}
                                        render={( { onChange, onBlur, value, name, ref },
                                                  { invalid, isTouched, isDirty }) => (
                                            <Select
                                                placeholder={'Select Branch'}
                                                classNamePrefix="react-select-sol-style"
                                                isDisabled={props.collectingGarages}
                                                isLoading={props.collectingGarages}
                                                maxMenuHeight={200}
                                                value={value}
                                                isClearable={true}
                                                control={control}
                                                inputRef={ref}
                                                options={branches}
                                                isSearchable={true}
                                                noOptionsMessage={() => "No branches available"}
                                                onChange={(selected, {action}) => {
                                                    onChange(selected)
                                                }}
                                            />
                                        )}
                                    />
                                </Form.Group>
                            </div>
                        </div>
                        <div className={'row'}>
                            <div className={"col-md-12"}>
                                <Button variant="warning" size={'sm'} type="submit"
                                        disabled={props.listLoading}>
                                    {language === 'EN'? 'Apply filter':'ফিল্টার করুন'}
                                </Button>
                                <Button variant="outline-dark" size={'sm'}
                                        onClick={()=> {resetFilter()}}
                                        className={'ml-3'} disabled={props.listLoading}>
                                    {language === 'EN'? 'Reset filter':'ফিল্টার করার তথ্য পরিষ্কার করুন'}
                                </Button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </>
    }
    // ----- End: Filter form design and event related ---- //

    // ----- Begin: Summary Section related ---- //
    const summaryInfo = () => {
        return <>
            <div className={isTabletOrMobile? 'row':'row ml-1 mt-7'}>
                {/* Following deisgn is copied from the Contract Management and kept as it is to keep open the scope of adding more section */}
                {!isTabletOrMobile? <>
                    <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={10}>
                        <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={1}
                        >
                            <div className={'custom-icon-holder'}>
                                <Avatar alt={'Users'}
                                        src={require('../../../../utils/asset/usersIcon.svg')}
                                        // sx={{ width: 23, height: 23 }}
                                        variant={'square'}/>
                            </div>
                            <Stack direction="column" justifyContent="flex-end" alignItems="flex-start" spacing={0}>
                                <h5><strong>{totalUsers}</strong></h5>
                                <small>Total</small>
                            </Stack>
                        </Stack>
                    </Stack>
                </>:<>
                    <div className={'col-md-12'}>
                        <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={1}
                        >
                            <div className={'custom-icon-holder'}>
                                <Avatar alt={'Users'}
                                        src={require('../../../../utils/asset/usersIcon.svg')}
                                        // sx={{ width: 23, height: 23 }}
                                        variant={'square'}/>
                            </div>
                            <Stack direction="column" justifyContent="flex-end" alignItems="flex-start" spacing={0}>
                                <h5><strong>{totalUsers}</strong></h5>
                                <small>Total</small>
                            </Stack>
                        </Stack>
                    </div>
                </>}
            </div>
        </>
    }
    // ----- End: Summary Section related ---- //

    // useEffect(() => {
    //     if (props.userList) {
    //         const userList = props.userList.results;
    //         if (userList && userList.length > -1) {
    //             setUserList(userList.map((user, index) => {
    //                 let status = 'Active'
    //                 if (user.status === false) {
    //                     status = 'Inactive'
    //                 }
    //                 return {
    //                     ...user,
    //                     garage: user.garage_name? user.garage_name:"N/A",
    //                     // role: user.role_name,
    //                     registeredAt: moment(user.created_at).format("DD MMMM,YYYY hh:mm a"),
    //                     status: status
    //                 }
    //             }))
    //         } else {
    //             setUserList(undefined)
    //         }
    //     }
    // }, [props.userList]);

    useEffect(() => {
        if (props.errorMessageForUserListCollection) {
            showNotifications('error', props.errorMessageForUserListCollection)
        }
    }, [props.errorMessageForUserListCollection])

    const onChangePage = (event, newPage) => {
        setPage(newPage)
        let searchText = 'page=' + newPage
        if (filterApplied) {
            if (filterParamForApiCall.user_id) {
                searchText += '&user_id=' + filterParamForApiCall.user_id
            }
            if (filterParamForApiCall.username) {
                searchText += '&username=' + filterParamForApiCall.username
            }
        }
        props.history.push({
            search: searchText
        })
    }

    const gotToCreateuser = () => {
        props.history.push({
            pathname: '/users/new-create-process',
            purpose: 'create'
        })
    }

    const goToUpdatePassword = (rowData) => {
        props.history.push({
            pathname: '/users/update-password/',
            userData: rowData
        })
    }

    return (
        <>
            <ContentWrapper showCardHeader={false} showBackButton={false} isLoading={props.isLoading}
                            serverError={false} permissionDenied={false} pageTitle={"Grids"}
                            statusCode={props.statusCode}>
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <Toolbar>
                            <Toolbar.Title>
                                <h1><b>{language === 'EN'? 'Users':'ব্যবহারকারীর তালিকা'}</b></h1>
                            </Toolbar.Title>
                            <Toolbar.ToolbarContainer>
                                <Toolbar.ToolbarContainer.Items>
                                    <IconButton onClick={filterToggle} classes={classes}>
                                        <FilterListIcon color="disabled" fontSize="large"/>
                                    </IconButton>
                                    <IconButton classes={classes} onClick={() => {console.log("Download button clicked")}}
                                                disabled={false}>
                                        {/*{props.exportDataCollectionInProgress? <Spinner animation={"grow"} variant={'warning'}*/}
                                        {/*                                                size={"lg"}/> :*/}
                                        {/*    <img src={require('../../../../utils/asset/download-Button.svg')}*/}
                                        {/*         alt="Download"/>}*/}
                                        <img src={require('../../../../utils/asset/download-Button.svg')}
                                             alt="Download"/>
                                    </IconButton>
                                </Toolbar.ToolbarContainer.Items>
                            </Toolbar.ToolbarContainer>
                        </Toolbar>
                    </div>
                </div>
                <hr/>
                {showFilter?
                    <>
                        {filterForm()}
                        <hr/>
                    </>:null}
                {summaryInfo()}
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <DataTableContainer>
                            {!props.listLoading && userList? <DataTable
                                language={language}
                                noDataAvailableMessageInBangla={'কোন ব্যবহারকারী নেই'}
                                noDataAvailableMessageInEnglish={'No users available'}
                                columns={userListColumns}
                                data={userList}
                                showToolbar={false}
                                asyncPagination={true}
                                isLoading={props.tableLoading}
                                count={props.userList?.count}
                                itemsPerPage={props.userList?.page_size}
                                onChangePage={onChangePage}
                                page={page}
                                actionColumnIndex={-1}
                                overrideCustomActions={true}
                                actions={checkAuthorization(authorization, userServiceName, userFeatureName, [updateActionName])? [
                                    {
                                        position: "row",
                                        action: (rowData) => ({
                                            icon: () => {
                                                return <><UserSettings userData={rowData}
                                                                       usersListFilterParams={filterParamForApiCall}
                                                                       history={props.history}/></>
                                            }
                                        })
                                    }
                                ]:[]}
                            /> : <LoadingSpinner loadingSubText={language === 'EN'? 'Collecting user list ..': 'ব্যবহারকারীদের তালিকা প্রস্তুত হচ্ছে'} language={language}/>}
                        </DataTableContainer>
                    </div>
                </div>

            </ContentWrapper>

            <style jsx>{`
              .filter-animation {
                animation-name: breath-in;
                animation-duration: 40ms;
              }

              @keyframes breath-in {
                0% {
                  height: 0;
                  opacity: 0;
                }
                10% {
                  height: 10px;
                  opacity: 0.10;
                }
                20% {
                  height: 20px;
                  opacity: 0.20;
                }
                30% {
                  height: 30px;
                  opacity: 0.30;
                }
                40% {
                  height: 40px;
                  opacity: 0.40;
                }
                50% {
                  height: 50px;
                  opacity: 0.50;
                }
                60% {
                  height: 60px;
                  opacity: 0.60;
                }
                70% {
                  height: 70px;
                  opacity: 0.70;
                }
                80% {
                  height: 80px;
                  opacity: 0.80;
                }
                90% {
                  height: 90px;
                  opacity: 0.90;
                }
                100% {
                  height: auto;
                  opacity: 1.00;
                }
              }

              //@keyframes breath-out {
              //    0% { height: auto;  opacity: 1.00; }
              //    10% { height: 90px;  opacity: 0.90; }
              //    20% { height: 80px;  opacity: 0.80; }
              //    30% { height: 70px;  opacity: 0.70; }
              //    40% { height: 60px;  opacity: 0.60; }
              //    50% { height: 50px;  opacity: 0.50; }
              //    60% { height: 40px;  opacity: 0.40; }
              //    70% { height: 30px;  opacity: 0.30; }
              //    80% { height: 20px;  opacity: 0.20; }
              //    90% { height: 10px;  opacity: 0.10; }
              //    100% { height: 0; opacity: 0;  }  
              //}


            `}</style>
        </>
    );
};

NewUserList.propTypes = {};

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        isLoading: state.usersReducer.isLoading,
        userList: state.usersReducer.userList,
        listLoading: state.usersReducer.listLoading,
        errorMessageForUserListCollection: state.usersReducer.errorMessageForUserListCollection
    }
}

export default connect(mapStateToProps, actions)(withRoles(NewUserList));