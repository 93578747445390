/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import {useLocation} from "react-router";
import {NavLink}  from "react-router-dom";
import MemoryIcon from '@mui/icons-material/Memory';
import ElectricRickshawIcon from '@mui/icons-material/ElectricRickshaw';
import DevicesIcon from '@mui/icons-material/Devices';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import DatasetIcon from '@mui/icons-material/Dataset';
import GradingIcon from '@mui/icons-material/Grading';
import InventoryIcon from '@mui/icons-material/Inventory';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import CodeIcon from '@mui/icons-material/Code';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import {toAbsoluteUrl, checkIsActive} from "../../_metronic/_helpers";
import {useSelector} from "react-redux";
import {getServiceName} from "../utils/roleRelatedValues/serviceNames";
import {getFeatureName} from "../utils/roleRelatedValues/featureNames";
import {getActionName} from "../utils/roleRelatedValues/actionNames";
import {checkAuthorization} from "../features/auth/authorization";
import moment from "moment";
import {Spinner} from "react-bootstrap";
import IconButton from "@material-ui/core/IconButton";
import FiberNewIcon from '@mui/icons-material/FiberNew';

export function AsideMenuList({ layoutProps }) {
  const language = useSelector(state => state.auth.language? state.auth.language:'EN')
  const authorizations = useSelector(state => state.auth.authorizations? state.auth.authorizations:{})
  const role = useSelector(state => state.auth.role? state.auth.role:"")
  const orgGuid = useSelector(state => state.auth.organization_guid? state.auth.organization_guid:"")
  // Service names
  const driverService = getServiceName('driverService')
  const deviceService = getServiceName('deviceService')
  const batteryService = getServiceName('batteryService')
  const userService = getServiceName('userService')
  const transactionService = getServiceName('transactionService')
  const garageService = getServiceName('garageService')
  // Feature names
  const driverFeature = getFeatureName('driverFeature')
  const evFeature = getFeatureName('evFeature')
  const batteryRentFeature = getFeatureName('batteryRentFeature')
  const userFeature = getFeatureName('userFeature')
  const roleFeature = getFeatureName('roleFeature')
  const deviceFeature = getFeatureName('deviceFeature')
  const appEventFeature = getFeatureName('appEventFeature')
  const otaUpdateFeature = getFeatureName('otaUpdateFeature')
  const firmwareFeature = getFeatureName('firmwareFeature')
  const hardwareFeature = getFeatureName('hardwareFeature')
  const batteryFeature = getFeatureName('batteryFeature')
  const vendorFeature = getFeatureName('vendorFeature')
  const modelFeature = getFeatureName('modelFeature')
  const garageFeature = getFeatureName('garageFeature')
  const historicalDataFeature = getFeatureName('historicalDataFeature')
  const smartDongleFeature = getFeatureName('smartDongleFeature')
  const productionLogFeature = getFeatureName('productionLogFeature')
  const contractManagementFeature = getFeatureName('contractManagementFeature')
  const invoiceFeature = getFeatureName('invoiceFeature')
  const transactionFeature = getFeatureName('transactionFeature')
  const mobileWalletFeature = getFeatureName('mobileWalletFeature')
  const depositFeature = getFeatureName('depositFeature')
  const settlementFeature = getFeatureName('settlementFeature')
  const invoiceSettlementFeature = getFeatureName('invoiceSettlementFeature')
  const financierFeature = getFeatureName('financierFeature')
  const overviewFeatureGarage = getFeatureName('dashboardOverviewFeatureForGarage')
  const overviewFeatureTransaction = getFeatureName('dashboardOverviewFeatureForTransaction')
  // Action names
  const listAction = getActionName('listAction')

  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url) ?
        `${!hasSubmenu && "menu-item-active"} menu-item-open`
        :
        "";
  };

  useEffect(() => {
    // For changing language at the calendars and dates
    if (language === 'BN') {
      moment.locale('bn');
    } else {
      moment.locale('en-gb')
    }
    // If no authorization JSON is available than dashboard will automatically log out
    if(!authorizations || !Object.keys(authorizations).length){
      document.location.href = '/logout';
    }
  }, [authorizations]);


  return (
      <React.Fragment>
        {/* begin::Menu Nav */}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          {/* begin:: 'Overview' section */}
          {/* Garage dashboard overview access is enough as "Total Batteries Deployed" card which represents data
          from Garage Service, will remain always visible, if user has access to overview page. Access to transaction
          overview access doesn't matter here - Noor Reza, 16th Jan, 2024 10:21 pm */}
          {checkAuthorization(authorizations, garageService, overviewFeatureGarage, [listAction])? <>
            <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                    "/overview/old", false
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
            >
              {/* TODO: Following url has been used for development purpose only */}
              <NavLink className="menu-link menu-toggle" to="/overview/old">
                  <span className="svg-icon menu-icon">
                      <img src={require('../utils/asset/battery-rent-settlements.svg')} alt={"ln"}/>
                  </span>
                <span className="menu-text sidebar-menu-item">Overview</span>
              </NavLink>
            </li>
            <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                    "/overview/new", false
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
            >
              {/* TODO: Following url has been used for development purpose only */}
              <NavLink className="menu-link menu-toggle" to="/overview/new">
                  <span className="svg-icon menu-icon">
                    <FiberNewIcon/>
                  </span>
                <span className="menu-text sidebar-menu-item">New Overview (test)</span>
              </NavLink>
            </li>
          </>:null}
          {/* end:: 'Overview' section */}
          {(checkAuthorization(authorizations, garageService, contractManagementFeature, [listAction]) ||
              checkAuthorization(authorizations, transactionService, invoiceSettlementFeature, [listAction]) ||
              checkAuthorization(authorizations, transactionService, invoiceFeature, [listAction]) ||
              checkAuthorization(authorizations, transactionService, mobileWalletFeature, [listAction]) ||
              checkAuthorization(authorizations, transactionService, transactionFeature, [listAction]))? <>
              {/*----Finance----*/}
              {/* begin::section */}
              <li className="menu-section">
                  <h4 className="menu-text">{language === 'EN'? 'Finance':'অর্থ ব্যবস্থাপনা'}</h4>
                  <i className="menu-icon flaticon-more-v2"></i>
              </li>
              {/* end:: section */}
              {/* begin:: sub section */}
              {checkAuthorization(authorizations, garageService, contractManagementFeature, [listAction])? <>
                <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                        "/contract-management/list", false
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                >
                  <NavLink className="menu-link menu-toggle" to="/contract-management/list">
                  <span className="svg-icon menu-icon">
                      <img src={require('../utils/asset/battery-rent-settlements.svg')} alt={"ln"}/>
                  </span>
                    <span className="menu-text sidebar-menu-item">{language === 'EN'? 'Contract Management':'ঋণসমূহ'}</span>
                  </NavLink>
                </li>
              </>:null}
              {/* end:: sub section */}
              {/* begin:: sub section */}
              {/*{checkAuthorization(authorizations, transactionService, invoiceSettlementFeature, [listAction])? <>*/}
              {/*  <li*/}
              {/*    className={`menu-item menu-item-submenu ${getMenuItemActive(*/}
              {/*        "/settlements", false*/}
              {/*    )}`}*/}
              {/*    aria-haspopup="true"*/}
              {/*    data-menu-toggle="hover"*/}
              {/*  >*/}
              {/*  <NavLink className="menu-link menu-toggle" to="/settlements/list">*/}
              {/*    <span className="svg-icon menu-icon">*/}
              {/*        <img src={require('../utils/asset/ic-weekly-report.svg')} alt={"ln"}/>*/}
              {/*    </span>*/}
              {/*    <span className="menu-text sidebar-menu-item">{language === 'EN'? 'Settlements':'Settlements'}</span>*/}
              {/*  </NavLink>*/}
              {/* </li>*/}
              {/*</>:null}*/}
              {/* end:: sub section */}
              {/* begin:: sub section */}
              {checkAuthorization(authorizations, transactionService, transactionFeature, [listAction])? <>
                <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                        "/payments/list", false
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                >
                  <NavLink className="menu-link menu-toggle" to="/payments/list">
                  <span className="svg-icon menu-icon">
                      <img src={require('../utils/asset/ic-transactions.svg')} alt={"mw"}/>
                  </span>
                    <span className="menu-text sidebar-menu-item">{language === 'EN'? 'Transactions':'Transactions'}</span>
                  </NavLink>
                </li>
              </>:null}
              {/* end:: sub section */}
              {/* begin:: sub section */}
              {checkAuthorization(authorizations, transactionService, invoiceFeature, [listAction])? <>
                <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                        "/invoices/list", false
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                >
                  <NavLink className="menu-link menu-toggle" to="/invoices/list">
                  <span className="svg-icon menu-icon">
                      <img src={require('../utils/asset/ic-invoices.svg')} alt={"inv"}/>
                  </span>
                    <span className="menu-text sidebar-menu-item">{language === 'EN'? 'Invoices': 'চালানসমূহ'}</span>
                  </NavLink>
                </li>
              </>:null}

            {/* begin:: sub section */}
            {/*{checkAuthorization(authorizations, transactionService, settlementFeature, [listAction])? <>*/}
            {/*  <li*/}
            {/*      className={`menu-item menu-item-submenu ${getMenuItemActive(*/}
            {/*          "/payments/settlements", false*/}
            {/*      )}`}*/}
            {/*      aria-haspopup="true"*/}
            {/*      data-menu-toggle="hover"*/}
            {/*  >*/}
            {/*    <NavLink className="menu-link menu-toggle" to="/payments/settlements">*/}
            {/*         <span className="svg-icon menu-icon">*/}

            {/*            <img src={require('../utils/asset/battery-rent-settlements.svg')} alt={"mw"}/>*/}
            {/*        </span>*/}
            {/*      <span className="menu-text sidebar-menu-item">{language === 'EN'? 'Battery Rent Settlements':'লেনদেনের লগ'}</span>*/}
            {/*    </NavLink>*/}
            {/*  </li>*/}
            {/*</>:null}*/}
            {/* end:: sub section */}

            {checkAuthorization(authorizations, transactionService, depositFeature, [listAction])? <>
              <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/deposits/list", false
                  )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/deposits/list">
                     <span className="svg-icon menu-icon">

                        <img src={require('../utils/asset/Vector.svg')} alt={"dt"}/>
                    </span>
                  <span className="menu-text sidebar-menu-item">{language === 'EN'? 'Deposits':'জমা সমূহ'}</span>
                </NavLink>
              </li>
            </>:null}
            {/* end:: sub section */}
            {/*----End of Finance----*/}


          </>:null}
          {/*----Operations----*/}
          {(checkAuthorization(authorizations, garageService, driverFeature, [listAction]) ||
              checkAuthorization(authorizations, garageService, batteryRentFeature, [listAction]) ||
              checkAuthorization(authorizations, garageService, garageFeature, [listAction]))? <>
            {/* Drivers */}
            {/* begin::section */}
            <li className="menu-section">
              <h4 className="menu-text">{language === 'EN'? 'Operations':'অপারেশনস'}</h4>
              <i className="menu-icon flaticon-more-v2"></i>
            </li>
            {/* end:: section */}
            {/* begin:: sub section */}
            {checkAuthorization(authorizations, garageService, batteryRentFeature, [listAction])? <>
              <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/checkout", false
                  )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/rents/logs">
                     <span className="svg-icon menu-icon">
                        <WorkHistoryIcon className={`sidebar-menu-item`} />
                    </span>
                  <span className="menu-text sidebar-menu-item">{language === 'EN'? 'Battery Rent Log':'ব্যাটারী ভাড়ার লগ'}</span>
                </NavLink>
              </li>
              <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/rds", false
                  )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/rds/rent-logs">
                     <span className="svg-icon menu-icon">
                        <WorkHistoryIcon className={`sidebar-menu-item`} />
                    </span>
                  <span className="menu-text sidebar-menu-item">Battery Rent Log RDS (test)</span>
                </NavLink>
              </li>
            </>:null}
            {/* end:: sub section */}
            {/* begin:: sub section */}
            {checkAuthorization(authorizations, garageService, driverFeature, [listAction])? <>
              <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/drivers", false
                  )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/drivers/list">
                      <span className="svg-icon menu-icon">
                        <i className="fa fa-id-card" aria-hidden="true"/>
                     </span>
                  <span className="menu-text sidebar-menu-item">{language === 'EN'? 'Drivers':'চালকদের তালিকা'}</span>
                </NavLink>
              </li>
            </>:null}
            {checkAuthorization(authorizations, deviceService, deviceFeature, [listAction])? <>
              {/* begin:: sub section */}
              <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/devices", false
                  )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/devices/list">
                   <span className="svg-icon menu-icon">
                     <img src={require('../utils/asset/smart-battery-icon.svg')} alt={"smi"}/>
                   </span>
                  <span className="menu-text sidebar-menu-item">{language === 'EN'? 'Smart batteries':'যন্ত্র'}</span>
                </NavLink>
              </li>
              {/* end:: sub section */}
            </>:null}
            {checkAuthorization(authorizations, garageService, evFeature, [listAction])? <>
              {/* begin:: sub section */}
              <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/evs", false
                  )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/evs/list">
                   <span className="svg-icon menu-icon">
                     <ElectricRickshawIcon className={`sidebar-menu-item`} />
                   </span>
                  <span className="menu-text sidebar-menu-item">{language === 'EN'? 'EVs':'ইভিসমূহ'}</span>
                </NavLink>
              </li>
              {/* end:: sub section */}
            </>:null}
            {/* begin:: sub section */}
            {(checkAuthorization(authorizations, garageService, garageFeature, [listAction]) &&
            orgGuid !== 'ea6ba698-f907-4530-9d7f-d82ca195ceb0')? <>
              <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/borrowers", false
                  )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/borrowers/list">
                      <span className="svg-icon menu-icon">
                        <img src={require('../utils/asset/ic-garages.svg')}/>
                     </span>
                  <span className="menu-text sidebar-menu-item">{language === 'EN'? 'Borrowers':'ঋণগ্রহীতা'}</span>
                </NavLink>
              </li>
            </>:null}
            {/* end:: sub section */}
          </>:null}
          {/*----End of Rent management----*/}

          {/*----Battery Management----*/}
          {(checkAuthorization(authorizations, batteryService, batteryFeature, [listAction]) ||
              checkAuthorization(authorizations, batteryService, vendorFeature, [listAction]) ||
              checkAuthorization(authorizations, batteryService, modelFeature, [listAction]) ||
              checkAuthorization(authorizations, batteryService, historicalDataFeature, [listAction]) ||
              checkAuthorization(authorizations, batteryService, productionLogFeature, [listAction]))? <>
            {/* begin::section */}
            <li className="menu-section">
              <h4 className="menu-text">{language === 'EN'? 'Battery Management':'ব্যাটারী ব্যবস্থাপনা'}</h4>
              <i className="menu-icon flaticon-more-v2"></i>
            </li>
            {/* end:: section */}
            {checkAuthorization(authorizations, batteryService, vendorFeature, [listAction]) ? <>
              {/* begin: subsection */}
              <li className={`menu-item menu-item-submenu ${getMenuItemActive("/batteries/vendors/list", false)}`}
                  aria-haspopup="true" data-menu-toggle="hover">
                <NavLink className="menu-link menu-toggle" to="/batteries/vendors/list">
                  <span className="svg-icon menu-icon">
                    <CorporateFareIcon className={`sidebar-menu-item`} />
                  </span>
                    <span className="menu-text sidebar-menu-item">
                    {language === 'EN' ? 'Vendors' : 'ব্যাটারীর তালিকা'}
                  </span>
                </NavLink>
              </li>
              {/* end:: sub section */}
            </> : null}
            {checkAuthorization(authorizations, batteryService, modelFeature, [listAction]) ? <>
              {/* begin: subsection */}
              <li className={`menu-item menu-item-submenu ${getMenuItemActive("/batteries/models/list", false)}`}
                  aria-haspopup="true" data-menu-toggle="hover">
                <NavLink className="menu-link menu-toggle" to="/batteries/models/list">
                  <span className="svg-icon menu-icon">
                    {/* <i className="fa fa-car-battery" aria-hidden="true" /> */}
                    <InventoryIcon className={`sidebar-menu-item`} />

                  </span>
                    <span className="menu-text sidebar-menu-item">
                    {language === 'EN' ? 'Models' : 'ব্যাটারীর তালিকা'}
                  </span>
                </NavLink>
              </li>
              {/* end:: sub section */}
            </> : null}
            {checkAuthorization(authorizations, batteryService, batteryFeature, [listAction])? <>
              {/* begin: subsection */}
              <li className={`menu-item menu-item-submenu ${getMenuItemActive("/batteries/list", false)}`}
                  aria-haspopup="true" data-menu-toggle="hover">
                <NavLink className="menu-link menu-toggle" to="/batteries/list">
                       <span className="svg-icon menu-icon">
                          <i className="fa fa-car-battery" aria-hidden="true"/>
                       </span>
                  <span className="menu-text sidebar-menu-item">
                         {language === 'EN'? 'Batteries':'ব্যাটারীর তালিকা'}
                       </span>
                </NavLink>
              </li>
              {/* end:: sub section */}
            </>:null}

            {/* begin:: sub section */}
            {checkAuthorization(authorizations, batteryService, historicalDataFeature, [listAction])? <>
              {role !== 'Mpower'? <>
                <li className={`menu-item menu-item-submenu ${getMenuItemActive("/batteries/battery_fleet", false)}`}
                    aria-haspopup="true" data-menu-toggle="hover">
                  <NavLink className="menu-link menu-toggle" to="/batteries/battery_fleet">
                     <span className="svg-icon menu-icon">
                        <i className="fa fa-chart-line" aria-hidden="true"/>
                     </span>
                    <span className="menu-text sidebar-menu-item">
                       {language === 'EN'? 'Battery Fleet Performance':'Chart Test'}
                     </span>
                  </NavLink>
                </li>
              </>:null}
              {/* end:: end section */}
              {/* begin:: sub section */}
              <li className={`menu-item menu-item-submenu ${getMenuItemActive("/battery-logs/list", false)}`}
                  aria-haspopup="true" data-menu-toggle="hover">
                <NavLink className="menu-link menu-toggle" to="/battery-logs/list">
                     <span className="svg-icon menu-icon">
                        <i className="fa fa-history" aria-hidden="true"/>
                     </span>
                  <span className="menu-text sidebar-menu-item">
                       {language === 'EN'? 'Data Log':'তথ্য লগ'}
                     </span>
                </NavLink>
              </li>
              {/* end:: sub section */}
              {/* begin:: sub section */}
              {role !== 'Mpower'? <>
                <li className={`menu-item menu-item-submenu ${getMenuItemActive("/historical-data/list", false)}`}
                    aria-haspopup="true" data-menu-toggle="hover">
                  <NavLink className="menu-link menu-toggle" to="/historical-data/list">
                     <span className="svg-icon menu-icon">
                       <DatasetIcon className={`sidebar-menu-item`} />
                     </span>
                    <span className="menu-text sidebar-menu-item">
                       {language === 'EN'? 'Historical Data':'হিষ্টোরিকাল তথ্য'}
                     </span>
                  </NavLink>
                </li>
              </>:null}
              {/* end: sub section */}
            </>:null}
            {/* end:: sub section */}
            {/* begin:: sub section */}
            {checkAuthorization(authorizations, batteryService, productionLogFeature, [listAction])? <>
              <li className={`menu-item menu-item-submenu ${getMenuItemActive("/pcb-qa-testing-results/list", false)}`}
                  aria-haspopup="true" data-menu-toggle="hover">
                <NavLink className="menu-link menu-toggle" to="/pcb-qa-testing-results/list">
                     <span className="svg-icon menu-icon">
                        <GradingIcon className={`sidebar-menu-item`} />
                     </span>
                  <span className="menu-text sidebar-menu-item">
                       {language === 'EN'? 'Production Logs':'প্রোডাকশন লগসমূহ'}
                     </span>
                </NavLink>
              </li>
            </>:null}
            {/* end:: sub section */}
          </>:null}
          {/*----End of Battery Management----*/}

          {/*----Device Management----*/}
          {/*----Device list----*/}
          {(checkAuthorization(authorizations, deviceService, smartDongleFeature, [listAction]) ||
              checkAuthorization(authorizations, deviceService, hardwareFeature, [listAction]) ||
              checkAuthorization(authorizations, deviceService, firmwareFeature, [listAction]) ||
              checkAuthorization(authorizations, deviceService, otaUpdateFeature, [listAction]))? <>
            {/* begin::section */}
            <li className="menu-section">
              <h4 className="menu-text">{language === 'EN'? 'Dongle Management':'ডঙ্গল ব্যবস্থাপনা'}</h4>
              <i className="menu-icon flaticon-more-v2"></i>
            </li>
            {/* end:: section */}
            {checkAuthorization(authorizations, deviceService, smartDongleFeature, [listAction])? <>
              {/* begin: subsection */}
              <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/dongles", false
                  )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/dongles/list">
                   <span className="svg-icon menu-icon">
                     <MemoryIcon className={`sidebar-menu-item`} />
                   </span>
                  <span className="menu-text sidebar-menu-item">{language === 'EN'? 'Smart Dongles':'স্মার্ট ডংগলের তালিকা'}</span>
                </NavLink>
              </li>
              {/* end:: sub section */}
            </>:null}
            {checkAuthorization(authorizations, deviceService, hardwareFeature, [listAction])? <>
              <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/ota/hardwares", false
                  )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/ota/hardwares">
                 <span className="svg-icon menu-icon">
                   <DeviceHubIcon className={`sidebar-menu-item`} />
                  </span>
                  <span className="menu-text sidebar-menu-item">{language === 'EN'? 'Hardwares':'হার্ডওয়্যারের তালিকা'}</span>
                </NavLink>
              </li>
            </>:null}
            {checkAuthorization(authorizations, deviceService, firmwareFeature, [listAction])? <>
              <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/ota/firmwares", false
                  )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/ota/firmwares">
                 <span className="svg-icon menu-icon">
                   <i className="fa fa-code" aria-hidden="true"/>
                  </span>
                  <span className="menu-text sidebar-menu-item">{language === 'EN'? 'Firmwares':'ফার্মওয়্যারের তালিকা'}</span>
                </NavLink>
              </li>
              <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/ota/new-firmwares-list", false
                  )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/ota/new-firmwares-list">
                 <span className="svg-icon menu-icon">
                   <CodeIcon className={"sidebar-menu-item"}/>
                  </span>
                  <span className="menu-text sidebar-menu-item">Firmwares (New)</span>
                </NavLink>
              </li>
            </>:null}
            {/* begin:: sub section */}
            {checkAuthorization(authorizations, deviceService, otaUpdateFeature, [listAction])? <>
              <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/ota/history", false
                  )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/ota/history">
                   <span className="svg-icon menu-icon">
                     <i className="fa fa-broadcast-tower" aria-hidden="true"/>
                  </span>
                  <span className="menu-text sidebar-menu-item">{language === 'EN'? 'OTA Updates':'ওটিএ আপডেটের তালিকা'}</span>
                </NavLink>
              </li>
            </>:null}
          </>:null}
          {/*----End of Device Management----*/}

          {/*----Event Management----*/}
          {/*----Event list----*/}
          {checkAuthorization(authorizations, deviceService, appEventFeature, [listAction])? <>
            {/* Mobile App events */}
            {/* begin::section */}
            <li className="menu-section">
              <h4 className="menu-text">{language === 'EN'? 'Event Management':'ঘটনা ব্যবস্থাপনা'}</h4>
              <i className="menu-icon flaticon-more-v2"></i>
            </li>
            {/* end:: section */}
            {/* begin:: sub section */}
            <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                    "/app-events", false
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/app-events/list">
                <span className="svg-icon menu-icon">
                  <i className="fa fa-mobile-alt" aria-hidden="true"/>
                </span>
                <span className="menu-text sidebar-menu-item">{language === 'EN'? 'Mobile App Events':'মোবাইল অ্যাপ সম্পর্কিত ঘটনাসমূহ'}</span>
              </NavLink>
            </li>
            {/* end:: sub section */}
          </>:null}
          {/*----End of Event Management----*/}

          {/*----User Management----*/}
          {/*----User list, Role list----*/}
          {((checkAuthorization(authorizations, userService, financierFeature, [listAction]) ||
                  checkAuthorization(authorizations, userService, userFeature, [listAction]) ||
                  checkAuthorization(authorizations, userService, roleFeature, [listAction])) &&
              orgGuid !== 'ea6ba698-f907-4530-9d7f-d82ca195ceb0')? <>
            {/* Users */}
            {/* begin::section */}
            <li className="menu-section">
              <h4 className="menu-text">{language === 'EN'? 'Administration':'প্রশাসন'}</h4>
              <i className="menu-icon flaticon-more-v2"></i>
            </li>
            {/* end:: section */}
            {/* begin:: sub section */}
            {checkAuthorization(authorizations, userService, financierFeature, [listAction])? <>
              <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/financiers", false
                  )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/financiers/list">
                  <span className="svg-icon menu-icon">
                    <img src={require('../utils/asset/ic-organizations.svg')}/>
                  </span>
                  <span className="menu-text sidebar-menu-item">{language === 'EN'? 'Organization - Financiers':'ব্যবহারকারীর তালিকা'}</span>
                </NavLink>
              </li>
            </>:null}
            {/* end:: sub section */}
            {/* begin:: sub section */}
            {checkAuthorization(authorizations, userService, userFeature, [listAction])? <>
              <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/users/list", false
                  )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/users/list">
                  <span className="svg-icon menu-icon">
                    <i className="fa fa-users" aria-hidden="true"/>
                  </span>
                  <span className="menu-text sidebar-menu-item">{language === 'EN'? 'Users':'ব্যবহারকারীর তালিকা'}</span>
                </NavLink>
              </li>
            </>:null}
            {checkAuthorization(authorizations, userService, userFeature, [listAction])? <>
              <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/users/new-version", false
                  )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/users/new-version">
                  <span className="svg-icon menu-icon">
                    {/*<i className="fa fa-users" aria-hidden="true"/>*/}
                    <StarBorderIcon/>
                  </span>
                  <span className="menu-text sidebar-menu-item">Users (New)</span>
                </NavLink>
              </li>
            </>:null}
            {checkAuthorization(authorizations, userService, roleFeature, [listAction])? <>
              <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/roles/list", false
                  )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/roles/list">
                  <span className="svg-icon menu-icon">
                    <i className="fa fa-tasks" aria-hidden="true"/>
                  </span>
                  <span className="menu-text sidebar-menu-item">{language === 'EN'? 'Roles':'ব্যবহারকারীর ধরনের তালিকা'}</span>
                </NavLink>
              </li>
              <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/roles/updated", false
                  )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/roles/updated">
                  <span className="svg-icon menu-icon">
                    <i className="fa fa-tasks" aria-hidden="true"/>
                  </span>
                  <span className="menu-text sidebar-menu-item">Roles (New)</span>
                </NavLink>
              </li>
            </>:null}
          </>:null}
          {/*----End of user management----*/}
          <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/payments/create", false
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/payments/create">
                  <span className="svg-icon menu-icon">
                    <i className="fa fa-tasks" aria-hidden="true"/>
                  </span>
              <span className="menu-text sidebar-menu-item">{language === 'EN'? 'Pay with bKash':'ব্যবহারকারীর ধরনের তালিকা'}</span>
            </NavLink>
          </li>
        </ul>
        {/* end::Menu Nav */}
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {"Dashboard v3.1.0"}
      </div>
      </React.Fragment>
  );
}