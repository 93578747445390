import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import {Toolbar} from "../../../smartDongles/views/components";
import {SolInput} from "../../../../components/SolStyledComponents/components";
import {LoadingSpinner} from '../../../../components/LoadingSpinnerForDataTable';
import DataTable from "../../../../components/dataTable/DataTable";
import {connect} from "react-redux";
import {actions} from "../../actions";
import {actions as commonActions} from "../../../commonReduxActions/actions";
import {Badge, Button, Spinner} from "react-bootstrap";
import {useSubheader} from "../../../../../_metronic/layout";
import {useLocation} from 'react-router';
import moment from "moment";
import {Form} from "react-bootstrap";
import Select from 'react-select';
import {Controller, useForm} from "react-hook-form";
import FwUpdateHistoriesTable from "../../../../components/fwUpdateHistory/FwUpdateHistoriesTable"
import {customStylesForMultiSelectField} from "../../../../utils/reactSelectRelated/customStylesForMultiSelect";
import {ValueContainer} from "../../../../utils/reactSelectRelated/showCustomizedValueLabelForMultiSelect";
import {Option} from "../../../../utils/reactSelectRelated/showOptionsWithCheckbox";
import {groupSelectedOptions} from "../../../../utils/reactSelectRelated/groupSelectedOptionsForMultiSelect";
import {customStylesForSingleSelectField} from "../../../../utils/reactSelectRelated/customStylesForSingleSelect";
import {formulateApiRequestDataFromSelectedItems} from "../../../../utils/multiSelectValueFormation/formatForApiRequest";
import Box from '@mui/material/Box';
import {showNotifications} from "../../../../utils/notification";
import {otaReducer} from "../../reducer";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import {DataTableContainerForDongleList} from "../../utils";
import Skeleton from "@mui/material/Skeleton";
import {makeStyles} from "@material-ui/styles";
import DateTimeFormat from "../../../../utils/dateFormat/DateTimeFormat";
import {GenericModal} from "../../../../components";
import {DataTableContainer} from "../../../rentLog/utils";
import Typography from "@mui/material/Typography";

const AssignDongles = props => {
    const {register, errors, control, clearErrors, handleSubmit, reset, setValue, setError} = useForm()
    const [authorization, setAuthorization] = useState(null)
    const [language, setLanguage] = useState(null)
    const subHeader = useSubheader()
    const location = useLocation()

    // Page related
    const [mainFirmware, setMainFirmware] = useState('')
    const [compatibleHwVersions, setCompatibleHwVersions] = useState([])

    // Filter related
    const [financiers, setFinanciers] = useState([])
    const [financier, setFinancier] = useState("")
    const [garages, setGarages] = useState([])
    const [garage, setGarage] = useState("")
    const [installedFirmwares, setInstalledFirmwares] = useState([])
    const [selectedFirmware, setSelectedFirmware] = useState('')

    // Tab styling and selection related
    const useStyles = makeStyles((theme) => ({
        tab: {
            '&:hover': {
                color: '#F18D00'
            },
            '&:focus': {
                color: '#F18D00'
            },
            '&.Mui-selected': {
                color: '#F18D00'
            },
            textTransform: 'none',
            ...theme.typography.h6
        },
        indicator: {
            backgroundColor: '#F18D00'
        }
    }));
    const classes = useStyles();
    const [tabValue, setTabValue] = useState(0)
    const TabPanel = ({...props}) => {
        const { children, value, index, ...other } = props;

        return (
            <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`}
                 {...other}>
                {value === index && (
                    <Box p={2}>{children}</Box>
                )}
            </div>
        );
    }
    // TODO: Following labels will be populated during dongle list collection
    const [eligibleDongleListLabel, setEligibleDongleListLabel] = useState('Eligible SOLdongles (2)')
    const [activeDongleListLabel, setActiveDongleListLabel] = useState('Active SOLdongles (2)')
    const [allowedDongleListLabel, setAllowedDongleListLabel] = useState('Allowed SOLdongles (2)')

    // Dongle list related
    const [showOldFwColumn, setShowOldFwColumn] = useState(false)
    const [eligibleDongles, setEligibleDongles] = useState([{
        "serial_number": "44000382",
        "firmware_version": "1.4.2",
        "hardware_version": "1.8.2",
        "device_sl": "323",
        "battery_vendor": "Green Tiger",
        "nominal_voltage": "58",
        "nameplate_capacity": "100",
        "bms_vendor": "Jia Baida"
    }, {
        "serial_number": "44000266",
        "firmware_version": "1.4.2",
        "hardware_version": "1.8.2",
        "device_sl": "323",
        "battery_vendor": "Green Tiger",
        "nominal_voltage": "58",
        "nameplate_capacity": "100",
        "bms_vendor": "Jia Baida"
    }])
    const [activeDongles, setActiveDongles] = useState([{
        "serial_number": "44000118",
        "firmware_version": "1.4.6",
        "old_firmware_version": "1.4.2",
        "hardware_version": "1.8.2",
        "device_sl": "323",
        "battery_vendor": "Green Tiger",
        "nominal_voltage": "58",
        "nameplate_capacity": "100",
        "bms_vendor": "Jia Baida"
    }, {
        "serial_number": "44000230",
        "firmware_version": "1.4.6",
        "old_firmware_version": "1.4.2",
        "hardware_version": "1.8.2",
        "device_sl": "323",
        "battery_vendor": "Green Tiger",
        "nominal_voltage": "58",
        "nameplate_capacity": "100",
        "bms_vendor": "Jia Baida"
    }])
    const [allowedDongles, setAllowedDongles] = useState([{
        "serial_number": "44000118",
        "firmware_version": "1.4.2",
        "hardware_version": "1.8.2",
        "device_sl": "323",
        "battery_vendor": "Green Tiger",
        "nominal_voltage": "58",
        "nameplate_capacity": "100",
        "bms_vendor": "Jia Baida"
    }, {
        "serial_number": "44000230",
        "firmware_version": "1.4.2",
        "hardware_version": "1.8.2",
        "device_sl": "323",
        "battery_vendor": "Green Tiger",
        "nominal_voltage": "58",
        "nameplate_capacity": "100",
        "bms_vendor": "Jia Baida"
    }])
    const commonColumns = [
        {
            field: 'serial_number',
            title: 'SOLdongle',
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData)=>{
                return   <>
                    <h6><strong>{rowData.serial_number}</strong></h6>
                    <span className={"text-nowrap"}>HW v{rowData.hardware_version}</span>
                </>
            }
        },
        {
            field: 'device_sl',
            title: 'Smart Battery',
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            render: (rowData) => {
                return <>
                    <h6><strong>{rowData.device_sl}</strong></h6>
                    <span className={"text-nowrap"}>{rowData.battery_vendor} | {rowData.nominal_voltage}V, {rowData.nameplate_capacity}Ah | {rowData.bms_vendor}</span>
                </>
            }
        },
        {
            field: 'firmware_version',
            title: 'Current F.W.',
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                textAlign: 'center'
            },
            headerStyle: {
                textAlign: 'center'
            },
            render: (rowData)=>{
                return   <>
                    <span className={"text-nowrap"}>{rowData.firmware_version}</span>
                </>
            }
        }
    ]
    const dongleListColumns = showOldFwColumn? [
            ...commonColumns,
        {
            field: 'old_firmware_version',
            title: 'OLD F.W.',
            emptyValue: ()=> {
                return "-"
            },
            cellStyle: {
                textAlign: 'center'
            },
            headerStyle: {
                textAlign: 'center'
            },
            render: (rowData)=>{
                return   <>
                    <span className={"text-nowrap"}>{rowData.old_firmware_version}</span>
                </>
            }
        }
    ]:[...commonColumns]

    // "See Update History" action related
    const [showFwUpdateHistoryModal, setShowFwUpdateHistoryModal] = useState(false)
    const [dongleForModal, setDongleForModal] = useState('')
    const [histories, setHistories] = useState(undefined)


    const {
        GET_ORGANISATIONS,
        GET_GARAGE_LIST,
        GET_FIRMWARE_LIST,
        GET_FIRMWARE_UPDATE_LIST,
        RETURN_TO_INITIAL_STATES_FOR_COMMON_REDUCER,
        RETURN_TO_INITIAL
    } = props

    useEffect(()=>{
        setAuthorization(props.authorization)
        setLanguage(props.language)
        subHeader.setBreadCrumbComponent(null)
        if (location.fwData) {
            let fw = location.fwData.version
            setMainFirmware(fw)
            sessionStorage.setItem("fwVersion", fw)

            let hwVersionList = location.fwData.hardware_version_list.map((hw) => {
                return {
                    label: hw,
                    value: hw
                }
            })
            setCompatibleHwVersions(hwVersionList)
            sessionStorage.setItem("hwVersions", JSON.stringify(hwVersionList))
        } else {
            if (sessionStorage.getItem("fwVersion")) {
                setMainFirmware(sessionStorage.getItem("fwVersion"))
            }
            if (sessionStorage.getItem("hwVersions")) {
                setCompatibleHwVersions(JSON.parse(sessionStorage.getItem("hwVersions")))
            }
        }
        if (props.isSolshareUser) {
            GET_ORGANISATIONS()
        }
        GET_GARAGE_LIST()
        GET_FIRMWARE_LIST()
        return ()=>{
            RETURN_TO_INITIAL_STATES_FOR_COMMON_REDUCER()
            RETURN_TO_INITIAL()
        }
    },[])

    // ----- Begin: Filtering process related ---- //
    useEffect(() => {
        if (props.organisations) {
            const organisations = props.organisations
            setFinanciers(organisations.map((org) => {
                return {
                    ...org,
                    label: org.name,
                    value: org.pk
                }
            }))
        } else {
            setFinanciers([])
        }
    },[props.organisations])

    useEffect(() => {
        if (props.garages) {
            const garages = props.garages
            setGarages(garages.map((garage) => {
                return {
                    ...garage,
                    label: garage.name,
                    value: garage.pk
                }
            }))
        } else {
            setGarages([])
        }
    },[props.garages])

    useEffect(() => {
        if (props.errorMessageForGarageListCollection) {
            showNotifications('error', props.errorMessageForGarageListCollection)
        }
    }, [props.errorMessageForGarageListCollection])

    useEffect(() => {
        if (props.firmwares) {
            const firmwares = props.firmwares
            setInstalledFirmwares(firmwares.map((firmware) => {
                return {
                    ...firmware,
                    label: firmware.version,
                    value: firmware.pk
                }
            }))
        } else {
            setInstalledFirmwares([])
        }
    },[props.firmwares])

    useEffect(() => {
        if (props.errorRelatedToFirmwareCollection) {
            showNotifications('error', props.errorRelatedToFirmwareCollection)
        }
    }, [props.errorRelatedToFirmwareCollection])

    const filterForm = () => {
        return <>
            <Form onSubmit={handleSubmit(collectFilteredDongles)}>
                <div className={"row g-3"}>
                    <div className={"col-md-3"}>
                        <Form.Group>
                            <div>
                                <Form.Label>SOLdongle ID</Form.Label>
                            </div>
                            <SolInput
                                name={'dongle_sl'}
                                type={'number'}
                                min={'1'}
                                step={'1'}
                                placeholder={'Type SOLdongle ID'}
                                autoComplete={"off"}
                                ref={register()}
                            />
                        </Form.Group>
                    </div>
                    {props.isSolshareUser? <>
                        <div className={"col-md-3"}>
                            <Form.Group>
                                <div>
                                    <Form.Label>Financier</Form.Label>
                                </div>
                                <Select
                                    placeholder={props.orgListLoading? 'Loading options..':'Select financiers'}
                                    name={'financier'} // This name is used to show 'n items selected' text
                                    classNamePrefix="react-select-sol-style"
                                    isDisabled={props.orgListLoading}
                                    isLoading={props.orgListLoading}
                                    maxMenuHeight={200}
                                    isClearable={true}
                                    options={financiers}
                                    value={financier}
                                    isSearchable={true}
                                    isMulti={true}
                                    hideSelectedOptions={false}
                                    styles={customStylesForMultiSelectField}
                                    components={{ ValueContainer, Option }}
                                    noOptionsMessage={() => "No financiers available"}
                                    onChange={(selected) => {
                                        setGarage('')
                                        if (selected) {
                                            setFinancier(selected)
                                            setFinanciers(groupSelectedOptions(selected, financiers))

                                            GET_GARAGE_LIST({'organization_guid': formulateApiRequestDataFromSelectedItems(selected)})
                                        } else {
                                            setFinancier('')

                                            GET_GARAGE_LIST()
                                        }
                                    }}
                                />
                            </Form.Group>
                        </div>
                    </>:''}
                    <div className={"col-md-3"}>
                        <Form.Group>
                            <div>
                                <Form.Label>Garage</Form.Label>
                            </div>
                            <Select
                                placeholder={props.collectingGarages? 'Loading options..':'Select garages'}
                                name={'garage'} // This name is used to show 'n items selected' text
                                classNamePrefix="react-select-sol-style"
                                isDisabled={props.collectingGarages}
                                isLoading={props.collectingGarages}
                                maxMenuHeight={200}
                                isClearable={true}
                                options={garages}
                                value={garage}
                                isSearchable={true}
                                isMulti={true}
                                hideSelectedOptions={false}
                                styles={customStylesForMultiSelectField}
                                components={{ ValueContainer, Option }}
                                noOptionsMessage={() => "No garages available"}
                                onChange={(selected) => {
                                    if (selected) {
                                        setGarage(selected)
                                        setGarages(groupSelectedOptions(selected, garages))
                                    } else {
                                        setGarage('')
                                    }
                                }}
                            />
                        </Form.Group>
                    </div>
                    <div className={"col-md-3"}>
                        <Form.Group>
                            <div>
                                <Form.Label>Installed Firmware</Form.Label>
                            </div>
                            <Select
                                placeholder={props.listLoading? 'Loading options..':'Select firmwares'}
                                name={'firmware'} // This name is used to show 'n items selected' text
                                classNamePrefix="react-select-sol-style"
                                isDisabled={props.listLoading}
                                isLoading={props.listLoading}
                                maxMenuHeight={200}
                                isClearable={true}
                                options={installedFirmwares}
                                value={selectedFirmware}
                                isSearchable={true}
                                isMulti={true}
                                hideSelectedOptions={false}
                                styles={customStylesForMultiSelectField}
                                components={{ ValueContainer, Option }}
                                noOptionsMessage={() => "No firmwares available"}
                                onChange={(selected) => {
                                    if (selected) {
                                        setSelectedFirmware(selected)
                                        setInstalledFirmwares(groupSelectedOptions(selected, installedFirmwares))
                                    } else {
                                        setSelectedFirmware('')
                                    }
                                }}
                            />
                        </Form.Group>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={"col-md-12"}>
                        <Button variant="outline-warning" size={'sm'} type="submit"
                                disabled={false}>
                            Preview SOLdongles
                        </Button>
                    </div>
                </div>
            </Form>
        </>
    }

    const collectFilteredDongles = (data) => {
        console.log(data.dongle_sl)
        console.log(formulateApiRequestDataFromSelectedItems(financier))
        console.log(formulateApiRequestDataFromSelectedItems(garage))
        console.log(formulateApiRequestDataFromSelectedItems(selectedFirmware))
    }

    // ----- End: Filtering process related ---- //

    // ----- Begin: Tab and SOLdongle list related ---- //
    const handleTabSelection = (event, newValue) => {
        setTabValue(newValue)
        if (newValue === 1) {
            setShowOldFwColumn(true)
        } else {
            setShowOldFwColumn(false)
        }
    }

    const onDongleSelection = (data) => {
        console.log(data)
    }

    const seeUpdateHistory = (data) => {
        GET_FIRMWARE_UPDATE_LIST({
            dongle_serial_number: data.serial_number
        })
        setDongleForModal(data.serial_number)
        setShowFwUpdateHistoryModal(true)
    }

    const hideUpdateHistoryModal = () => {
        setShowFwUpdateHistoryModal(false)
        setHistories(undefined)
    }

    useEffect(() => {
        if (props.otaList) {
            const updates = props.otaList
            setHistories(updates.map((fwUpdate) => {
                return {
                    ...fwUpdate
                }
            }))
        } else {
            setHistories(undefined)
        }
    },[props.otaList])

    useEffect(() => {
        if (props.errorForOtaListCollection) {
            setHistories([])
        }
    }, [props.errorForOtaListCollection])

    const renderUpdateHistoryModal = () => {
        return <>
            <GenericModal
                size={'lg'}
                showModalHeader={true}
                footer={false}
                hideModal={hideUpdateHistoryModal}
                modal={showFwUpdateHistoryModal}
                title={<h1><strong>Firmware update history of <span style={{color: "#F18D00"}}>{dongleForModal}</span></strong></h1>}>
                <div className={"row"}>
                    <div className={"col-md-12"}>
                        {!props.collectingOtaList && histories? <>
                            <DataTableContainerForDongleList>
                                <FwUpdateHistoriesTable histories={histories} pageSize={5}/>
                            </DataTableContainerForDongleList>
                        </>:<>
                            <Typography variant="h5">
                                <Skeleton className={"d-flex justify-content-center align-items-center"}
                                          variant="rect" sx={{ color: '#F18D00' }} width={'100%'}
                                          height={477}>
                                    Collecting firmware update list ...
                                </Skeleton>
                            </Typography>
                        </>}
                    </div>
                </div>
                <div className={'row mt-3'}>
                    <div className={'col-md-12'}>
                        <Button variant={"outline-dark"} size={'md'} onClick={hideUpdateHistoryModal}
                                className={'float-right'} disabled={false}>
                            Close
                        </Button>
                    </div>
                </div>
            </GenericModal>
        </>
    }
    // ----- End: Tab and SOLdongle list related ---- //

    // ----- Begin: Page action related ---- //
    const closeThePage = () => {
        props.history.push({
            pathname: '/ota/new-firmwares-list'
        })
    }
    // ----- End: Page action related ---- //

    return (
        <ContentWrapper isLoading={false} pageTitle={"Firmware List"} showBackButton={false} showCardHeader={false}>
            <div className={"row"}>
                <div className={"col-md-12"}>
                    <Toolbar>
                        <Toolbar.Title>
                            <h1><strong>Assign Dongles for firmware {mainFirmware? <>v{mainFirmware}</>:''}</strong></h1>
                        </Toolbar.Title>
                    </Toolbar>
                    <hr/>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-md-4"}>
                    <Form.Group>
                        <div>
                            <Form.Label><strong>Hardware Compatibility</strong></Form.Label>
                        </div>
                        <Controller
                            control={control}
                            name={"hw_compatibility"}
                            rules={{}}
                            render={({ onChange, onBlur, value, name, ref },
                                     { invalid, isTouched, isDirty }) => (
                                <Select
                                    placeholder={'Select Hardware'}
                                    classNamePrefix="react-select-sol-style-access-management"
                                    maxMenuHeight={200}
                                    value={value}
                                    isClearable={true}
                                    control={control}
                                    inputRef={ref}
                                    options={compatibleHwVersions}
                                    isSearchable={true}
                                    isMulti={true}
                                    styles={customStylesForSingleSelectField}
                                    onChange={(selected, {action}) => {
                                        onChange(selected)
                                    }}
                                />
                            )}
                        />
                    </Form.Group>
                </div>
            </div>
            {/* Filter form */}
            <div className={"row mt-6"}>
                <div className={"col-md-12"}>
                    <Form.Label><strong>Select SOLdongles</strong></Form.Label>
                </div>
            </div>
            <div className={"row mt-3"}>
                <div className={"col-md-12"}>
                    <Box sx={{border: '1px solid #E9E9E9', padding: '16px', borderRadius: '16px'}}>
                        <div className={"row mb-5"}>
                            <div className={"col-md-12"}>
                                SELECTION CRITERIA
                            </div>
                        </div>
                        {filterForm()}
                    </Box>
                </div>
            </div>
            {/* Table */}
            <div className={"row mt-10"}>
                <div className={"col-md-12"}>
                    <Tabs
                        value={tabValue}
                        onChange={handleTabSelection}
                        aria-label="operational logs"
                        classes={{ indicator: classes.indicator}}
                    >
                        <Tab label={eligibleDongleListLabel} className={classes.tab}/>
                        <Tab label={activeDongleListLabel} className={classes.tab}/>
                        <Tab label={allowedDongleListLabel} className={classes.tab}/>
                    </Tabs>
                    <TabPanel value={tabValue} index={0}>
                        <div className={"row"}>
                            <div className={"col-md-12"}>
                                <DataTableContainerForDongleList>
                                    <DataTable
                                        language={language}
                                        noDataAvailableMessageInEnglish={'No dongles available'}
                                        columns={dongleListColumns}
                                        data={eligibleDongles}
                                        showToolbar={false}
                                        asyncPagination={false}
                                        pagination={true}
                                        pageSize={5}
                                        selection={true}
                                        showSelectAllCheckbox={true}
                                        onSelectionChange={onDongleSelection}
                                        actionColumnIndex={-1}
                                        overrideCustomActions={true}
                                        actions={[
                                            {
                                                position: "row",
                                                action: (rowData) => ({
                                                    icon: () => {
                                                        return <Button variant={'warning'}
                                                                       size={'sm'}
                                                                       style={{ whiteSpace: 'nowrap' }}>
                                                            See Update History
                                                        </Button>
                                                    },
                                                    onClick: (e, row) => {seeUpdateHistory(row)},
                                                })
                                            }
                                        ]}
                                    />
                                </DataTableContainerForDongleList>
                            </div>
                        </div>
                        <div className={"row mt-3"}>
                            <Button type={"button"} variant={"warning"} size={"sm"} className={"ml-3"}
                                    onClick={() => {console.log("here")}}>Allow Firmware Update</Button>
                            <Button type={"button"} variant={"outline-dark"} size={"sm"} className={"ml-3"}
                                    onClick={closeThePage}>Close</Button>
                        </div>
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        <DataTableContainerForDongleList>
                            <DataTable
                                language={language}
                                noDataAvailableMessageInEnglish={'No dongles available'}
                                columns={dongleListColumns}
                                data={activeDongles}
                                showToolbar={false}
                                asyncPagination={false}
                                pagination={true}
                                pageSize={5}
                                actionColumnIndex={-1}
                                overrideCustomActions={true}
                                actions={[
                                    {
                                        position: "row",
                                        action: (rowData) => ({
                                            icon: () => {
                                                return <Button variant={'warning'}
                                                               size={'sm'}
                                                               style={{ whiteSpace: 'nowrap' }}>
                                                    See Update History
                                                </Button>
                                            },
                                            onClick: (e, row) => {seeUpdateHistory(row)},
                                        })
                                    }
                                ]}
                            />
                        </DataTableContainerForDongleList>
                    </TabPanel>
                    <TabPanel value={tabValue} index={2}>
                        <DataTableContainerForDongleList>
                            <DataTable
                                language={language}
                                noDataAvailableMessageInEnglish={'No dongles available'}
                                columns={dongleListColumns}
                                data={allowedDongles}
                                showToolbar={false}
                                asyncPagination={false}
                                pagination={true}
                                pageSize={5}
                                actionColumnIndex={-1}
                                overrideCustomActions={true}
                                actions={[
                                    {
                                        position: "row",
                                        action: (rowData) => ({
                                            icon: () => {
                                                return <Button variant={'warning'}
                                                               size={'sm'}
                                                               style={{ whiteSpace: 'nowrap' }}>
                                                    See Update History
                                                </Button>
                                            },
                                            onClick: (e, row) => {seeUpdateHistory(row)},
                                        })
                                    }
                                ]}
                            />
                        </DataTableContainerForDongleList>
                    </TabPanel>
                </div>
            </div>
            {renderUpdateHistoryModal()}
        </ContentWrapper>
    );
};

AssignDongles.propTypes = {};

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        isSolshareUser: state.auth.is_solshare_user,
        orgListLoading: state.commonReducer.orgListLoading,
        organisations: state.commonReducer.organisations,
        collectingGarages: state.commonReducer.collectingGarages,
        garages: state.commonReducer.garages,
        errorMessageForGarageListCollection: state.commonReducer.errorMessageForGarageListCollection,
        listLoading: state.otaReducer.listLoading,
        firmwares: state.otaReducer.firmwares,
        errorRelatedToFirmwareCollection: state.otaReducer.errorRelatedToFirmwareCollection,
        otaList: state.otaReducer.otaList,
        collectingOtaList: state.otaReducer.collectingOtaList,
        errorForOtaListCollection: state.otaReducer.errorForOtaListCollection
    }
}

export default connect(mapStateToProps, {...actions, ...commonActions})(AssignDongles);
