import {Redirect, Route, Switch} from "react-router-dom";
import React from "react";
import SecuredRoute, {withRoles} from "../../router/SecuredRoute";
import HardwareList from "./views/hardware/HardwareList";
import FirmwareList from "./views/firmware/FirmwareList";
import NewFirmwareList from "./views/firmware/NewFirmwareList";
import FirmwareUpdateHistory from "./views/FirmwareUpdateHistory";
import CreateFirmware from "./views/firmware/CreateFirmware";
import NewCreateFirmware from "./views/firmware/NewFirmwareCreationPage";
import AssignDongles from "./views/firmware/AssignDonglesToFw";
import CreateHardware from "./views/hardware/CreateHardware";
import {getServiceName} from "../../utils/roleRelatedValues/serviceNames";
import {getFeatureName} from "../../utils/roleRelatedValues/featureNames";
import {getActionName} from "../../utils/roleRelatedValues/actionNames";


export const OtaRouter =(props)=> {

    return (
        <Switch>
            {/*Redirects the device if no Particular component */}
            <Redirect
                exact={"true"}
                from={"/ota"}
                to={"/ota/hardwares"}
            />

            {/* Routes to Hardware List*/}
            <SecuredRoute
                exact={"true"}
                path={"/ota/hardwares"}
                component={HardwareList}
                service={getServiceName('deviceService')}
                feature={getFeatureName('hardwareFeature')}
                action={getActionName('listAction')}
            />
            <SecuredRoute
                exact={"true"}
                path={"/ota/hardwares/new"}
                component={CreateHardware}
                service={getServiceName('deviceService')}
                feature={getFeatureName('hardwareFeature')}
                action={getActionName('createAction')}
            />
            <SecuredRoute
                exact={"true"}
                path={"/ota/hardwares/edit"}
                component={CreateHardware}
                service={getServiceName('deviceService')}
                feature={getFeatureName('hardwareFeature')}
                action={getActionName('updateAction')}
            />
            <SecuredRoute
                exact={"true"}
                path={"/ota/firmwares"}
                component={FirmwareList}
                service={getServiceName('deviceService')}
                feature={getFeatureName('firmwareFeature')}
                action={getActionName('listAction')}
            />
                <SecuredRoute
                    exact={"true"}
                    path={"/ota/new-firmwares-list"}
                    component={NewFirmwareList}
                    service={getServiceName('deviceService')}
                    feature={getFeatureName('firmwareFeature')}
                    action={getActionName('listAction')}
                />
            <SecuredRoute
                exact={"true"}
                path={"/ota/firmwares/new"}
                component={CreateFirmware}
                service={getServiceName('deviceService')}
                feature={getFeatureName('firmwareFeature')}
                action={getActionName('createAction')}
            />
            <SecuredRoute
                exact={"true"}
                path={"/ota/new-firmwares-list/new"}
                component={NewCreateFirmware}
                service={getServiceName('deviceService')}
                feature={getFeatureName('firmwareFeature')}
                action={getActionName('createAction')}
            />
            <SecuredRoute
                exact={"true"}
                path={"/ota/firmwares/edit"}
                component={CreateFirmware}
                service={getServiceName('deviceService')}
                feature={getFeatureName('firmwareFeature')}
                action={getActionName('updateAction')}
            />
                <SecuredRoute
                    exact={"true"}
                    path={"/ota/new-firmwares-list/edit"}
                    component={NewCreateFirmware}
                    service={getServiceName('deviceService')}
                    feature={getFeatureName('firmwareFeature')}
                    action={getActionName('createAction')}
                />
            {/* TODO: Add new permission related validation */}
            <SecuredRoute
                exact={"true"}
                path={"/ota/new-firmwares-list/assign-dongles"}
                component={AssignDongles}
                service={getServiceName('deviceService')}
                feature={getFeatureName('firmwareFeature')}
                action={getActionName('updateAction')}
            />
            <SecuredRoute
                exact={"true"}
                path={"/ota/new-firmwares-list/edit"}
                component={NewCreateFirmware}
                service={getServiceName('deviceService')}
                feature={getFeatureName('firmwareFeature')}
                action={getActionName('createAction')}
            />
            {/* TODO: Add new permission related validation */}
            <SecuredRoute
                exact={"true"}
                path={"/ota/new-firmwares-list/assign-dongles"}
                component={AssignDongles}
                service={getServiceName('deviceService')}
                feature={getFeatureName('firmwareFeature')}
                action={getActionName('updateAction')}
            />
            <SecuredRoute
                exact={"true"}
                path={"/ota/new-firmwares-list/edit"}
                component={NewCreateFirmware}
                service={getServiceName('deviceService')}
                feature={getFeatureName('firmwareFeature')}
                action={getActionName('createAction')}
            />
            {/* TODO: Add new permission related validation */}
            <SecuredRoute
                exact={"true"}
                path={"/ota/new-firmwares-list/assign-dongles"}
                component={AssignDongles}
                service={getServiceName('deviceService')}
                feature={getFeatureName('firmwareFeature')}
                action={getActionName('updateAction')}
            />
            <SecuredRoute
                path={"/ota/history"}
                component={FirmwareUpdateHistory}
                service={getServiceName('deviceService')}
                feature={getFeatureName('otaUpdateFeature')}
                action={getActionName('listAction')}
            />

            <Redirect to="/error/error-v1" />
        </Switch>
    )
};
