import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Controller, useForm} from "react-hook-form";
import { useLocation } from "react-router";
import {Button, Form, InputGroup, OverlayTrigger, Spinner, Card, Accordion} from "react-bootstrap";
import IconButton from "@material-ui/core/IconButton";
import Select from "react-select";
import {SolInput, SolTextArea} from "../../../../components/SolStyledComponents/components";
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import {actions} from "../../actions";
import {actions as commonActions} from "../../../commonReduxActions/actions";
import {connect} from "react-redux";
import {toast} from "react-toastify";
import {requestCycle} from "../../utils";
import {rolesReducer} from "../../reducer";
import {Toolbar} from "../components";
import {showNotifications} from "../../../../utils/notification";
import {LoadingSpinner} from '../../../../components/LoadingSpinnerForDataTable';
import {makeStyles} from "@material-ui/styles";
import FilterListIcon from "@material-ui/icons/FilterList";
import AccordionForFeatureRepresentation from "../../../../components/accordionForShowingFeatureList";
import {useSubheader} from "../../../../../_metronic/layout";
import {useMediaQuery} from "react-responsive";
import InfoIcon from '@mui/icons-material/Info';
import {styled} from "@mui/material/styles";
import Tooltip, {tooltipClasses} from "@mui/material/Tooltip";


const useStylesIconButton = makeStyles({
    root: {
        marginTop: '0',
        paddingTop: '4px',
    },
});

const UpdatedRoleCreationAndUpdate = props => {
    const {register, control, errors, setValue} = useForm();
    const location = useLocation();
    const [language, setLanguage] = useState('EN');
    const [role, setRole] = useState(sessionStorage.getItem('roleName')? sessionStorage.getItem('roleName'):"");
    const [rolePk, setRolePk] = useState(sessionStorage.getItem('rolePrimaryKey'));
    const [org, setOrg] = useState(null);
    const [organisations, setOrganisations] = useState([]);
    const [branches, setBranches] = useState([]);
    const [accessLevels, setAccessLevels] = useState([
        {
            'label': 'Administrator',
            'value': 'administrator'
        },
        {
            'label': 'Operational',
            'value': 'operational'
        },
        {
            'label': 'Viewer',
            'value': 'viewer'
        }
    ]);
    const [divisions, setDivisions] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [subDistricts, setSubDistricts] = useState([]);
    const [errorMessage, setErrorMessage] = useState({});
    const subHeader = useSubheader()

    const [featureList, setFeatureList] = useState([]);
    const [featureListForRepresentation, setFeatureListForRepresentation] = useState([]);
    const [selectedActionsByUser, setSelectedActionsByUser] = useState([]);
    const [selectedReferences, setSelectedReferences] = useState([]);
    const [edit, setEdit] = useState(sessionStorage.getItem('edit'));
    const [preSelectedPermissions, setPreSelectedPermission] = useState(sessionStorage.getItem('permissions') ? JSON.parse(sessionStorage.getItem('permissions')):[]);

    const [showTooltipForBranchField, setShowTooltipForBranchField] = useState(false);
    const [showTooltipForDistrictField, setShowTooltipForDistrictField] = useState(false);
    const [showTooltipForSubDistrictField, setShowTooltipForSubDistrictField] = useState(false);
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const LightTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: '0.875rem',
            fontWeight: 400
        }
    }));

    const {
        COLLECT_FEATURE_LIST,
        GET_ORGANISATIONS,
        GET_BRANCHES_LIST,
        COLLECT_DIVISIONS,
        COLLECT_DISTRICTS,
        COLLECT_SUB_DISTRICTS,
        RETURN_TO_INITIAL_STATES_FOR_COMMON_REDUCER,
        CREATE_ROLE,
        UPDATE_ROLE
    } = props;
    const classes = useStylesIconButton();

    useEffect(() => {
        setLanguage(props.language)
        COLLECT_FEATURE_LIST()
        GET_ORGANISATIONS()
        COLLECT_DIVISIONS()
        subHeader.setActionButtons(null)
        if (location.detailsData)
        {
            sessionStorage.setItem('edit', true)
            sessionStorage.setItem('roleName', location.detailsData.name)
            sessionStorage.setItem('rolePrimaryKey', location.detailsData.pk)
            sessionStorage.setItem('org', location.detailsData.organization_guid)
            sessionStorage.setItem('permissions', JSON.stringify(location.detailsData.permissions))
            setRole(location.detailsData.name)
            setRolePk(location.detailsData.pk)
            setPreSelectedPermission(location.detailsData.permissions)
            setEdit(true)
        }


        return ()=>{
            clearSessionStorage()
            RETURN_TO_INITIAL_STATES_FOR_COMMON_REDUCER()
        }
    }, [])

    useEffect(()=>{
        const organisations = props.organisations
        if (organisations && organisations.length >= 0) {
            setOrganisations(organisations.map((organisation) => {
                if (edit && organisation.pk === sessionStorage.getItem('org'))
                {
                    setOrg({
                        label: organisation.name,
                        value: organisation.pk
                    })
                }
                return {
                    label: organisation.name,
                    value: organisation.pk
                }
            }));
        }
    },[props.organisations])

    useEffect(()=>{
        const branches = props.branches
        if (branches && branches.length >= 0) {
            setBranches(branches.map((branch) => {
                return {
                    label: branch.name,
                    value: branch.pk
                }
            }))
        }
    },[props.branches])

    useEffect(() => {
        if (props.errorMessageForBranchCreation) {
            showNotifications('error', props.errorMessageForBranchCreation)
        }
    }, [props.errorMessageForBranchCreation])

    useEffect(() => {
        if (props.divisions && props.divisions.length > -1) {
            const divisions = props.divisions
            setDivisions(divisions.map((division) => {
                return {
                    ...division,
                    value: division.id,
                    label: division.name
                }
            }))
        } else {
            setDivisions([])
        }
    }, [props.divisions])

    useEffect(() => {
        if (props.districts && props.districts.length > -1) {
            const districts = props.districts
            setDistricts(districts.map((district) => {
                return {
                    ...district,
                    value: district.id,
                    label: district.name
                }
            }))
        } else {
            setDistricts([])
        }
    }, [props.districts])

    useEffect(() => {
        if (props.subDistricts && props.subDistricts.length > -1) {
            const subDistricts = props.subDistricts
            setSubDistricts(subDistricts.map((subDistrict) => {
                return {
                    ...subDistrict,
                    value: subDistrict.id,
                    label: subDistrict.name
                }
            }))
        } else {
            setSubDistricts([])
        }
    }, [props.subDistricts])

    useEffect(() => {
        let featureList = props.featureList
        setFeatureList(featureList)
        if (featureList) {
            let featureRows = []
            let thisRowFeatures = []
            let selectedActions = []
            for (let i=0; i<featureList.length; i++) {
                let actions = []
                let selectedActionCount = 0
                let keepAccordionOpen = false
                let accordionEventKey = i + 1
                for (let j=0; j<featureList[i].permissions.length; j++) {
                    let actionIsPreSelected = false
                    if (edit && preSelectedPermissions.length > 0) {
                        for (let k=0; k<preSelectedPermissions.length; k++) {
                            if (preSelectedPermissions[k].feature_name === featureList[i].feature_name &&
                                preSelectedPermissions[k].action_name === featureList[i].permissions[j].action_name) {
                                actionIsPreSelected = true
                                selectedActionCount += 1
                                // Remainder: In case of update, we are only saving preselected Action JSON ("action_json") for submit
                                // While in case of creation, 'selectedActionsByUser' will hold all information related to selected action
                                // At the time of submission, only "action_json" is accessed
                                selectedActions.push({'action_json': preSelectedPermissions[k],
                                    'actionName': featureList[i].permissions[j].action_name + "_" + featureList[i].permissions[j].feature_name})
                                break
                            }
                        }
                    }
                    actions.push({'label': featureList[i].permissions[j].action_title,
                        'featureName': featureList[i].permissions[j].feature_name,
                        'actionName': featureList[i].permissions[j].action_name + "_" + featureList[i].permissions[j].feature_name,
                        'action_json': featureList[i].permissions[j],
                        'selected': actionIsPreSelected})
                }
                if (selectedActionCount > 0) {
                    keepAccordionOpen = true
                }
                if (i === featureList.length - 1) {
                    if (i % 4 === 0) {
                        if (thisRowFeatures.length > 1) {
                            featureRows.push(thisRowFeatures)
                        }
                        featureRows.push([{
                            featureName: featureList[i].feature_title,
                            actions: actions,
                            keepAccordionOpen: keepAccordionOpen,
                            selectedActions: selectedActionCount,
                            accordionEventKey: accordionEventKey
                        }])
                    } else {
                        thisRowFeatures.push({
                            featureName: featureList[i].feature_title,
                            actions: actions,
                            keepAccordionOpen: keepAccordionOpen,
                            selectedActions: selectedActionCount,
                            accordionEventKey: accordionEventKey
                        })
                        featureRows.push(thisRowFeatures)
                    }
                } else if (i % 4 === 0 && i !== 0) {
                    featureRows.push(thisRowFeatures)
                    thisRowFeatures = []
                    thisRowFeatures.push({
                        featureName: featureList[i].feature_title,
                        actions: actions,
                        keepAccordionOpen: keepAccordionOpen,
                        selectedActions: selectedActionCount,
                        accordionEventKey: accordionEventKey
                    })
                } else {
                    thisRowFeatures.push({
                        featureName: featureList[i].feature_title,
                        actions: actions,
                        keepAccordionOpen: keepAccordionOpen,
                        selectedActions: selectedActionCount,
                        accordionEventKey: accordionEventKey
                    })
                }
            }
            setSelectedActionsByUser(selectedActions)
            setFeatureListForRepresentation(featureRows)
        }
    }, [props.featureList])

    const renderRows = (features) => {
        let rows = []
        features.map((row) => {
            rows.push(<div className={'row g-3'}>
                <div className={'col-md-3 mb-2'}>
                    <AccordionForFeatureRepresentation featureName={row[0].featureName}
                                                       keepAccordionOpen={row[0].keepAccordionOpen}
                                                       accordionEventKey={row[0].accordionEventKey}
                        // accordionEventKey={0}
                                                       onCheck={saveCheckedActions}
                                                       actions={row[0].actions}
                                                       selectedActions={row[0].selectedActions}
                                                       language={language}/>
                </div>
                {row[1]? <div className={'col-md-3 mb-2'}>
                    <AccordionForFeatureRepresentation featureName={row[1].featureName}
                                                       keepAccordionOpen={row[1].keepAccordionOpen}
                                                       accordionEventKey={row[1].accordionEventKey}
                        // accordionEventKey={0}
                                                       onCheck={saveCheckedActions}
                                                       actions={row[1].actions}
                                                       selectedActions={row[1].selectedActions}
                                                       language={language}/>
                </div>: null}
                {row[2]? <div className={'col-md-3 mb-2'}>
                    <AccordionForFeatureRepresentation featureName={row[2].featureName}
                                                       keepAccordionOpen={row[2].keepAccordionOpen}
                                                       accordionEventKey={row[2].accordionEventKey}
                        // accordionEventKey={0}
                                                       onCheck={saveCheckedActions}
                                                       actions={row[2].actions}
                                                       selectedActions={row[2].selectedActions}
                                                       language={language}/>
                </div>:null}
                {row[3]? <div className={'col-md-3 mb-2'}>
                    <AccordionForFeatureRepresentation featureName={row[3].featureName}
                                                       keepAccordionOpen={row[3].keepAccordionOpen}
                                                       accordionEventKey={row[3].accordionEventKey}
                        // accordionEventKey={0}
                                                       onCheck={saveCheckedActions}
                                                       actions={row[3].actions}
                                                       selectedActions={row[3].selectedActions}
                                                       language={language}/>
                </div>:null}
            </div>)
        })
        return <>{rows}</>
    }

    // const saveCheckedActions = (actions, references, isChecked) => {
    const saveCheckedActions = (actions, isChecked) => {
        clearErrorMessages()
        if (isChecked) {
            // Adding action and saving references
            let selectedActions = selectedActionsByUser
            selectedActions = selectedActions.concat(actions)
            setSelectedActionsByUser(selectedActions)
            // let refs = selectedReferences
            // refs = selectedReferences.concat(references)
            // setSelectedReferences(refs)
        } else {
            // Removing action and references
            let selectedActions = selectedActionsByUser
            // let references = selectedReferences
            for (let i=0; i<actions.length; i++) {
                selectedActions = selectedActions.filter(el => el.actionName !== actions[i].actionName)
                // references = selectedReferences.filter(el => el !== references[i])
            }
            setSelectedActionsByUser(selectedActions)
            // setSelectedReferences(references)
        }
    }

    const clearErrorMessages = () => {
        setErrorMessage({})
    }

    const clearSessionStorage = () => {
        sessionStorage.removeItem('edit')
        sessionStorage.removeItem('permissions')
        sessionStorage.removeItem('org')
        sessionStorage.removeItem('roleName')
        sessionStorage.removeItem('rolePrimaryKey')
    }

    const submitRoleInfo = () => {
        if (!role) {
            setErrorMessage({"role": true})
        } else if (!org) {
            setErrorMessage({"organisation": true})
        } else if (selectedActionsByUser.length === 0) {
            setErrorMessage({'actionSelection': true})
        } else {
            let permissions = []
            for (let i=0; i<selectedActionsByUser.length; i++) {
                permissions.push(selectedActionsByUser[i].action_json)
            }
            let payload = {
                "name": role,
                "organization_guid": org.value,
                "permissions": permissions
            }
            if (edit) {
                if (!rolePk) {
                    if (language === 'EN') {
                        toast.error("Can not update the role! Please contact with admin.")
                    } else {
                        toast.error("সম্পাদনা করা সম্ভব নয়! দয়া করে এডমিনের সাথে যোগাযোগ করুন।")
                    }
                } else {
                    UPDATE_ROLE(rolePk, payload)
                    clearSessionStorage()
                }
            } else {
                CREATE_ROLE(payload)
            }
        }
    }

    // const resetSelection = () => {
    //     if (selectedReferences.length > 1) {
    //         for (let i=0; i<selectedReferences.length; i++) {
    //             selectedReferences[i].checked = false
    //         }
    //         setResetSelectedActions(true)
    //         setSelectedReferences([])
    //         setSelectedActionsByUser([])
    //     }
    // }

    useEffect(()=>{
        if (props.roleCreated === requestCycle.success){
            if (language === 'EN') {
                toast.success("Role is created successfully!")
            } else {
                toast.success("সফলভাবে ধরন তৈরি হয়েছে!")
            }
            props.history.push('/roles/list/');
        } else if (props.roleCreated === requestCycle.failed) {
            if (language === 'EN') {
                showNotifications('error', props.errorMessageRoleCreation)
            } else {
                toast.error("তথ্য সংরক্ষণের চেষ্টা ব্যর্থ্য হয়েছে!")
            }
        }
    },[props.roleCreated])

    useEffect(()=>{
        if (props.roleUpdated === requestCycle.success){
            if (language === 'EN') {
                toast.success("Role is updated successfully!")
            } else {
                toast.success("সফলভাবে সম্পাদনা সম্পন্ন হয়েছে!")
            }
            props.history.goBack()
        } else if (props.roleUpdated === requestCycle.failed) {
            if (language === 'EN') {
                showNotifications('error', props.errorMessageRoleUpdate)
            } else {
                toast.error("সম্পাদনার চেষ্টা ব্যর্থ হয়েছে!")
            }
        }
    },[props.roleUpdated])

    const informationIsRequired = 'Above information is required!'

    // Tooltip for different fields
    const toolTipTextForBranchField = 'Only branches under the selected organization will be available for selection'
    const toolTipForBranchField = () => {
        return <>
            <LightTooltip
                title={toolTipTextForBranchField} placement="right" arrow componentsProps={{
                tooltip: {
                    sx: {
                        '& .MuiTooltip-arrow': {
                            color: 'common.white'
                        }
                    }
                }}} open={showTooltipForBranchField}
                onOpen={() => setShowTooltipForBranchField(true)}
                onClose={() => setShowTooltipForBranchField(false)}>
                <InfoIcon size={'sm'} style={{color: '#F18D00'}} onClick={() => {
                    if (isTabletOrMobile) {
                        setShowTooltipForBranchField(!showTooltipForBranchField)
                    }
                }}/>
            </LightTooltip>
        </>
    }

    const toolTipTextForDistrictField = 'Only districts under the selected division will be available for selection'
    const toolTipForDistrictField = () => {
        return <>
            <LightTooltip
                title={toolTipTextForDistrictField} placement="right" arrow componentsProps={{
                tooltip: {
                    sx: {
                        '& .MuiTooltip-arrow': {
                            color: 'common.white'
                        }
                    }
                }}} open={showTooltipForDistrictField}
                onOpen={() => setShowTooltipForDistrictField(true)}
                onClose={() => setShowTooltipForDistrictField(false)}>
                <InfoIcon size={'sm'} style={{color: '#F18D00'}} onClick={() => {
                    if (isTabletOrMobile) {
                        setShowTooltipForBranchField(!showTooltipForDistrictField)
                    }
                }}/>
            </LightTooltip>
        </>
    }

    const toolTipTextForSubDistrictField = 'Only sub-districts under the selected district will be available for selection'
    const toolTipForSubDistrictField = () => {
        return <>
            <LightTooltip
                title={toolTipTextForSubDistrictField} placement="right" arrow componentsProps={{
                tooltip: {
                    sx: {
                        '& .MuiTooltip-arrow': {
                            color: 'common.white'
                        }
                    }
                }}} open={showTooltipForSubDistrictField}
                onOpen={() => setShowTooltipForSubDistrictField(true)}
                onClose={() => setShowTooltipForSubDistrictField(false)}>
                <InfoIcon size={'sm'} style={{color: '#F18D00'}} onClick={() => {
                    if (isTabletOrMobile) {
                        setShowTooltipForSubDistrictField(!showTooltipForSubDistrictField)
                    }
                }}/>
            </LightTooltip>
        </>
    }

    return (
        <ContentWrapper showCardHeader={false} pageTitle={'Register user'} showBackButton={false} isLoading={false}>
            <div className={"row mb-6"}>
                <div className={"col-md-12"}>
                    <Toolbar>
                        <Toolbar.Title>
                            <h1>
                                <b>Create Role</b>
                            </h1>
                        </Toolbar.Title>
                    </Toolbar>
                </div>
            </div>
            <hr/>
            <Form>
                <div className={"row g-3"}>
                    <div className={"col-md-4"}>
                        <Form.Group>
                            <Form.Label>Role Name<span className="required text-danger">*</span></Form.Label>
                            <SolInput
                                type={"text"}
                                name={"role"}
                                placeholder={"Type name..."}
                                autoComplete={"off"}
                                ref={register({
                                    required: informationIsRequired,
                                })}
                            />
                            {errors.role && <div className="text-danger">
                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.role.message}
                            </div>}
                        </Form.Group>
                    </div>
                </div>
                <div className={'row g-3 mb-4'}>
                    <div className={"col-md-6"}>
                        <span className={"text-left"}><b>This role can access the smart batteries of:</b></span>
                    </div>
                </div>
                <div className={'row g-3'}>
                    <div className={'col-md-4'}>
                        <Form.Group>
                            <div>
                                <Form.Label>Organisation<span className="required text-danger">*</span></Form.Label>
                            </div>
                            <Controller
                                control={control}
                                name={"organisation"}
                                rules={{ required: informationIsRequired }}
                                defaultValue={''}
                                render={( { onChange, onBlur, value, name, ref },
                                          { invalid, isTouched, isDirty }) => (
                                    <Select
                                        placeholder={'Select Organisation'}
                                        classNamePrefix="react-select-sol-style"
                                        isDisabled={props.orgListLoading}
                                        isLoading={props.orgListLoading}
                                        maxMenuHeight={200}
                                        isClearable={true}
                                        isSearchable={true}
                                        control={control}
                                        value={value}
                                        options={organisations}
                                        noOptionsMessage={() => "No organizations available"}
                                        onChange={(selected, {action}) => {
                                            if (action === "clear") {
                                                setValue("branch", "")
                                                setBranches([])
                                            }
                                            onChange(selected)
                                            if (selected) {
                                                setValue("branch", "")
                                                GET_BRANCHES_LIST({'organization_guid': selected.value})
                                            }
                                        }}
                                    />
                                )}
                            />
                            {errors.organisation && <div className="text-danger">
                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.organisation.message}
                            </div>}
                        </Form.Group>
                    </div>
                    <div className={'col-md-4'}>
                        <Form.Group>
                            <div>
                                <Form.Label>Branch<span className="required text-danger">*</span>&nbsp;{toolTipForBranchField()}</Form.Label>
                            </div>
                            <Controller
                                control={control}
                                name={"branch"}
                                rules={{ required: informationIsRequired }}
                                defaultValue={''}
                                render={( { onChange, onBlur, value, name, ref },
                                          { invalid, isTouched, isDirty }) => (
                                    <Select
                                        placeholder={'Select Branch'}
                                        classNamePrefix="react-select-sol-style"
                                        isDisabled={props.collectingBranches}
                                        isLoading={props.collectingBranches}
                                        maxMenuHeight={200}
                                        isClearable={true}
                                        isSearchable={true}
                                        control={control}
                                        value={value}
                                        options={branches}
                                        noOptionsMessage={() => "No branches available"}
                                        onChange={(selected, {action}) => {
                                            onChange(selected)
                                        }}
                                    />
                                )}
                            />
                            {errors.branch && <div className="text-danger">
                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.branch.message}
                            </div>}
                        </Form.Group>
                    </div>
                    <div className={'col-md-4'}>
                        <Form.Group>
                            <div>
                                <Form.Label>Access Level<span className="required text-danger">*</span></Form.Label>
                            </div>
                            <Controller
                                control={control}
                                name={"access_level"}
                                rules={{ required: informationIsRequired }}
                                defaultValue={''}
                                render={( { onChange, onBlur, value, name, ref },
                                          { invalid, isTouched, isDirty }) => (
                                    <Select
                                        placeholder={'Select Access Level'}
                                        classNamePrefix="react-select-sol-style"
                                        isDisabled={props.orgListLoading}
                                        isLoading={props.orgListLoading}
                                        maxMenuHeight={200}
                                        isClearable={true}
                                        isSearchable={true}
                                        control={control}
                                        value={value}
                                        options={accessLevels}
                                        noOptionsMessage={() => "No options available"}
                                        onChange={(selected, {action}) => {
                                            onChange(selected)
                                        }}
                                    />
                                )}
                            />
                            {errors.access_level && <div className="text-danger">
                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.access_level.message}
                            </div>}
                        </Form.Group>
                    </div>
                </div>
                <div className={'row g-3'}>
                    <div className={'col-md-4'}>
                        <Form.Group>
                            <div>
                                <Form.Label>Division<span className="required text-danger">*</span></Form.Label>
                            </div>
                            <Controller
                                control={control}
                                name={"division"}
                                rules={{ required: informationIsRequired }}
                                defaultValue={''}
                                render={( { onChange, onBlur, value, name, ref },
                                          { invalid, isTouched, isDirty }) => (
                                    <Select
                                        placeholder={'Select Division'}
                                        classNamePrefix="react-select-sol-style"
                                        isDisabled={props.collectingDivisions}
                                        isLoading={props.collectingDivisions}
                                        maxMenuHeight={200}
                                        isClearable={true}
                                        isSearchable={true}
                                        control={control}
                                        value={value}
                                        options={divisions}
                                        noOptionsMessage={() => "No divisions available"}
                                        onChange={(selected, {action}) => {
                                            if (action === "clear") {
                                                setValue("district", "")
                                                setDistricts([])

                                                setValue("subdistrict", "")
                                                setSubDistricts([])
                                            }
                                            onChange(selected)
                                            if (selected) {
                                                setValue("district", "")
                                                COLLECT_DISTRICTS(selected.value)

                                                setValue("subdistrict", "")
                                                setSubDistricts([])
                                            }
                                        }}
                                    />
                                )}
                            />
                            {errors.division && <div className="text-danger">
                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.division.message}
                            </div>}
                        </Form.Group>
                    </div>
                    <div className={'col-md-4'}>
                        <Form.Group>
                            <div>
                                <Form.Label>District<span className="required text-danger">*</span>&nbsp;{toolTipForDistrictField()}</Form.Label>
                            </div>
                            <Controller
                                control={control}
                                name={"district"}
                                rules={{ required: informationIsRequired }}
                                defaultValue={''}
                                render={( { onChange, onBlur, value, name, ref },
                                          { invalid, isTouched, isDirty }) => (
                                    <Select
                                        placeholder={'Select District'}
                                        classNamePrefix="react-select-sol-style"
                                        isDisabled={props.collectingDistricts}
                                        isLoading={props.collectingDistricts}
                                        maxMenuHeight={200}
                                        isClearable={true}
                                        isSearchable={true}
                                        control={control}
                                        value={value}
                                        options={districts}
                                        noOptionsMessage={() => "No districts available"}
                                        onChange={(selected, {action}) => {
                                            if (action === "clear") {
                                                setValue("subdistrict", "")
                                                setSubDistricts([])
                                            }
                                            onChange(selected)
                                            if (selected) {
                                                setValue("subdistrict", "")
                                                COLLECT_SUB_DISTRICTS(selected.value)
                                            }
                                        }}
                                    />
                                )}
                            />
                            {errors.district && <div className="text-danger">
                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.district.message}
                            </div>}
                        </Form.Group>
                    </div>
                    <div className={'col-md-4'}>
                        <Form.Group>
                            <div>
                                <Form.Label>Sub-District<span className="required text-danger">*</span>&nbsp;{toolTipForSubDistrictField()}</Form.Label>
                            </div>
                            <Controller
                                control={control}
                                name={"subdistrict"}
                                rules={{ required: informationIsRequired }}
                                defaultValue={''}
                                render={( { onChange, onBlur, value, name, ref },
                                          { invalid, isTouched, isDirty }) => (
                                    <Select
                                        placeholder={'Select Sub-district'}
                                        classNamePrefix="react-select-sol-style"
                                        isDisabled={props.collectingSubDistricts}
                                        isLoading={props.collectingSubDistricts}
                                        maxMenuHeight={200}
                                        isClearable={true}
                                        isSearchable={true}
                                        control={control}
                                        value={value}
                                        options={subDistricts}
                                        noOptionsMessage={() => "No subdistricts available"}
                                        onChange={(selected, {action}) => {
                                            onChange(selected)
                                        }}
                                    />
                                )}
                            />
                            {errors.subdistrict && <div className="text-danger">
                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.subdistrict.message}
                            </div>}
                        </Form.Group>
                    </div>
                </div>
            </Form>
            {props.featureListCollectionGoingOn? <LoadingSpinner language={language} loadingSubText={language === "EN"? "Collecting feature list":"ফিচারের তালিকা সংগ্রহ করা হচ্ছে"}/>:
                <>
                    <Form>
                        {/* Dashboard features */}
                        <div className={"row mb-4"}>
                            <div className={"col-md-12"}>
                                <h3>
                                    <strong>Dashboard Features</strong>
                                </h3>
                            </div>
                        </div>
                        <div className={'row g-3 mb-4'}>
                            <div className={"col-md-6"}>
                                <span className={"text-left"}><b>{language === 'EN'? 'Select features and actions that will be accessible by this role':'ব্যবহারকারীর এই ধরনটি যে যে ফিচার দেখতে পারবে এবং একশন নিতে পারবে তা বাছাই করুন'}:</b></span>
                                {errorMessage.actionSelection? <div className="text-danger">
                                    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{language === 'EN'? 'Please select at least one action!':'দয়া করে অন্তত একটি একশন বাছাই করুন!'}</div>:null}
                            </div>
                        </div>
                        {renderRows(featureListForRepresentation)}
                        {/* Dashboard features */}

                        {/* Cholo Garage Mobile App features */}
                        <div className={"row mb-4 mt-4"}>
                            <div className={"col-md-12"}>
                                <h3>
                                    <strong>Cholo Garage Mobile App Features</strong>
                                </h3>
                            </div>
                        </div>
                        <div className={'row g-3 mb-4'}>
                            <div className={"col-md-6"}>
                                <span className={"text-left"}><b>{language === 'EN'? 'Select features and actions that will be accessible by this role':'ব্যবহারকারীর এই ধরনটি যে যে ফিচার দেখতে পারবে এবং একশন নিতে পারবে তা বাছাই করুন'}:</b></span>
                                {errorMessage.actionSelection? <div className="text-danger">
                                    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{language === 'EN'? 'Please select at least one action!':'দয়া করে অন্তত একটি একশন বাছাই করুন!'}</div>:null}
                            </div>
                        </div>
                        {renderRows(featureListForRepresentation)}
                        {/* Cholo Garage Mobile App features */}

                        {/* Cholo Driver Mobile App features */}
                        <div className={"row mb-4 mt-4"}>
                            <div className={"col-md-12"}>
                                <h3>
                                    <strong>Cholo Driver Mobile App Features</strong>
                                </h3>
                            </div>
                        </div>
                        <div className={'row g-3 mb-4'}>
                            <div className={"col-md-6"}>
                                <span className={"text-left"}><b>{language === 'EN'? 'Select features and actions that will be accessible by this role':'ব্যবহারকারীর এই ধরনটি যে যে ফিচার দেখতে পারবে এবং একশন নিতে পারবে তা বাছাই করুন'}:</b></span>
                                {errorMessage.actionSelection? <div className="text-danger">
                                    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{language === 'EN'? 'Please select at least one action!':'দয়া করে অন্তত একটি একশন বাছাই করুন!'}</div>:null}
                            </div>
                        </div>
                        {renderRows(featureListForRepresentation)}
                        {/* Cholo Driver Mobile App features */}

                        <div className={'row g-3 mt-1'}>
                            <div className={"col-md-12"}>
                                <Button variant="warning" type="button" size={'md'} disabled={props.roleInfoSubmitting}
                                        onClick={()=>{
                                            submitRoleInfo()
                                        }}>
                                    {props.roleInfoSubmitting? <><Spinner animation={'border'} size={'sm'} variant={'light'}/></>:null}
                                    <i className='fa fa-check' aria-hidden='true'/>&nbsp;Save
                                </Button>
                                {/*<Button variant="danger" type="button" size={'sm'} className={"ml-3"}*/}
                                {/*        disabled={false} onClick={()=>{resetSelection()}}>*/}
                                {/*    Reset*/}
                                {/*</Button>*/}
                                <Button variant="dark" size={'md'} className={"ml-3"}
                                        onClick={()=>{
                                            clearSessionStorage()
                                            props.history.goBack()
                                        }} style={{
                                    backgroundColor: '#8C8C9B',
                                    outline: '#8C8C9B',
                                    border: '#8C8C9B',
                                }}>
                                    Cancel&nbsp;<i className='fa fa-times' aria-hidden='true'/>
                                </Button>
                            </div>
                        </div>
                    </Form>
                </>}
            <style jsx>{`
              .react-select-sol-style__control:hover, .react-select-sol-style__control:focus, .react-select-sol-style__control:active{
                    border: 1px solid #F18D00;
                    box-shadow: 0 0 0 3.2px rgba(241, 141, 0, 0.1);
                    outline: none;  
                    }
                    
                .custom-popover{
                  min-width: fit-content;
                  min-height: fit-content;
                }
            `}</style>
        </ContentWrapper>
    );
};

UpdatedRoleCreationAndUpdate.propTypes = {};


const mapStateToProps=(state)=>{

    return {
        language: state.auth.language,
        featureListCollectionGoingOn: state.rolesReducer.featureListCollecting,
        featureList: state.rolesReducer.featureList,
        organisations: state.rolesReducer.organisations,
        organisationListLoading: state.rolesReducer.organisationListLoading,
        roleInfoSubmitting: state.rolesReducer.roleInfoSubmitting,
        roleCreated: state.rolesReducer.roleCreated,
        successMessageRoleCreation: state.rolesReducer.successMessageRoleCreation,
        errorMessageRoleCreation: state.rolesReducer.errorMessageRoleCreation,
        roleUpdated: state.rolesReducer.roleUpdated,
        errorMessageRoleUpdate: state.rolesReducer.errorMessageRoleUpdate,
        collectingBranches: state.commonReducer.collectingBranches,
        branches: state.commonReducer.branches,
        errorMessageForBranchCreation: state.commonReducer.errorMessageForBranchCreation,
        divisions: state.commonReducer.divisions,
        collectingDivisions: state.commonReducer.collectingDivisions,
        districts: state.commonReducer.districts,
        collectingDistricts: state.commonReducer.collectingDistricts,
        subDistricts: state.commonReducer.subDistricts,
        collectingSubDistricts: state.commonReducer.collectingSubDistricts
    }
}

export default connect(mapStateToProps, {...actions, ...commonActions}) (UpdatedRoleCreationAndUpdate);
