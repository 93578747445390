import {Redirect, Route, Switch} from "react-router-dom";
import React from "react";
import UserList from "./views/userList/userList";
import NewUserList from "./views/userList/newUserList";
import CreateUser from "./views/userForms/CreateUser";
import CreateUserV2 from "./views/userForms/newCreateUserForm";
import UpdatePasword from "./views/userForms/updatePassword";
import SecuredRoute, {withRoles} from "../../router/SecuredRoute";
import {getServiceName} from "../../utils/roleRelatedValues/serviceNames";
import {getFeatureName} from "../../utils/roleRelatedValues/featureNames";
import {getActionName} from "../../utils/roleRelatedValues/actionNames";


export const UsersRouter =(props)=> {

    return (
        <Switch>
            {/*Redirects the user if no Particular component */}
            <Redirect
                exact={"true"}
                from={"/users"}
                to={"/users/list"}
            />

            {/* Routes to User List*/}
            <SecuredRoute
                path={"/users/list"}
                component={UserList}
                service={getServiceName('userService')}
                feature={getFeatureName('userFeature')}
                action={getActionName('listAction')}
            />

            <SecuredRoute
                path={"/users/new-version"}
                component={NewUserList}
                service={getServiceName('userService')}
                feature={getFeatureName('userFeature')}
                action={getActionName('listAction')}
            />

            <SecuredRoute
                path={"/users/create"}
                component={CreateUser}
                service={getServiceName('userService')}
                feature={getFeatureName('userFeature')}
                action={getActionName('createAction')}
            />

            <SecuredRoute
                path={"/users/new-create-process"}
                component={CreateUserV2}
                service={getServiceName('userService')}
                feature={getFeatureName('userFeature')}
                action={getActionName('createAction')}
            />

            <SecuredRoute
                path={"/users/edit/"}
                component={CreateUser}
                service={getServiceName('userService')}
                feature={getFeatureName('userFeature')}
                action={getActionName('updateAction')}
            />

            <SecuredRoute
                path={"/users/new-edit-process"}
                component={CreateUserV2}
                service={getServiceName('userService')}
                feature={getFeatureName('userFeature')}
                action={getActionName('createAction')}
            />

            <SecuredRoute
                path={"/users/update-password/"}
                component={UpdatePasword}
                service={getServiceName('userService')}
                feature={getFeatureName('userFeature')}
                action={getActionName('updateAction')}
            />

            <Redirect to="/error/error-v1" />
        </Switch>
    )
};
