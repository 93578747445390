import React, { useEffect } from 'react';
import { useState } from 'react';
import {Button, Form, Dropdown, DropdownButton} from "react-bootstrap";
import {connect} from 'react-redux';
import { actions } from '../../actions';
import {GenericModal} from "../../../../components/genericModal/genericModal";
import moment from "moment";
import TableContainer from "@mui/material/TableContainer";
import Table from "react-bootstrap/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Stack from "@mui/material/Stack";
import {SolInput} from "../../../../components";
import DataTable from "../../../../components/dataTable/DataTable";
import {Controller, useForm} from "react-hook-form";
import Select from "react-select";
import {customStylesForSingleSelectField} from "../../../../utils/reactSelectRelated/customStylesForSingleSelect";
import SettingsIcon from "@mui/icons-material/Settings";
import {checkAuthorization} from "../../../auth/authorization";
import "../customCssForUserFeature.css"


const UserSettings = ({ userData, usersListFilterParams, history, ...props }) => {
    const {register, errors, control, clearErrors, handleSubmit, reset, setValue, setError} = useForm();
    const [language, setLanguage] = useState('EN')

    // Overdue limit set related
    const [showOverdueLimitModal, setShowOverdueLimitModal] = useState(false)
    const [applicableSmartBatteries, setApplicableSmartBatteriesColumns] = useState([{
        "contracts": "SB #52 Contract #55",
        "overdue": "2,500",
        "next_payment": "Oct 15, 2022 9:23 am"
    }, {
        "contracts": "SB #52 Contract #55",
        "overdue": "2,500",
        "next_payment": "Oct 15, 2022 9:23 am"
    }, {
        "contracts": "SB #52 Contract #55",
        "overdue": "2,500",
        "next_payment": "Oct 15, 2022 9:23 am"
    }])
    const [changeHistories, setChangeHistories] = useState([{
        "date": "Oct 15, 2022 9:23 am",
        "previous_amount": "8,500",
        "changed_to": "10,000",
        "changed_by": "Muktadir Billah",
        "applicable_to": "All Contracts"
    }, {
        "date": "Oct 14, 2022 9:23 am",
        "previous_amount": "9,500",
        "changed_to": "8,500",
        "changed_by": "Muktadir Billah",
        "applicable_to": "58, 69, 25"
    }])

    // Reset Password related
    const [showResetPasswordModal, setShowResetPasswordModal] = useState(false)
    const passwordUpdatePlatforms = [
        {label: 'Dashboard', value: 'dashboard'},
        {label: 'Garage App', value: 'garage_app'},
        {label: 'Driver App', value: 'driver_app'}
    ]

    // User Deactivate/delete confirmation modal related
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [confirmationModalTitle, setConfirmationModalTitle] = useState(false)

    // Table header styling
    const tableHeaderLeftAlignedColumnStyling = {
        fontWeight: 500,
        fontSize: "1.1rem",
        color: "#8C8C9B",
        // paddingLeft: "0px",
        textAlign: "left",
        // width: 'calc(6.16667px)',
        boxSizing: "borderBox"
    }

    const tableHeaderRightAlignedColumnStyling = {
        fontWeight: 500,
        fontSize: "1.1rem",
        color: "#8C8C9B",
        paddingLRight: "0px",
        textAlign: "right",
        boxSizing: "borderBox"
    }

    // Table cell related styling
    const tableLeftAlignedContentStyling = {
        color: "inherit",
        // width: calc(112.5px),
        boxSizing: "border-box",
        fontSize: "inherit",
        fontFamily: "inherit",
        fontWeight: "inherit",
        // paddingLeft: "0px",
        // width: 'calc(47.333px)',
        textAlign: "left",
        // whiteSpace: 'nowrap'
    }

    const tableRightAlignedContentStyling = {
        color: "inherit",
        // width: calc(112.5px),
        boxSizing: "border-box",
        fontSize: "inherit",
        fontFamily: "inherit",
        fontWeight: "inherit",
        // paddingRight: "0px",
        // width: 'calc(47.333px)',
        textAlign: "right",
        // whiteSpace: 'nowrap'
    }

    const applicableSmartBatteriesColumns = [
        {
            field: 'contracts',
            title: 'Contracts',
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            }
        },
        {
            field: 'overdue',
            title: 'Overdue Amount',
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: (rowData)=>{
                return   <>
                    <h6 style={{color: "#CB152B"}}><strong>{rowData.overdue}</strong></h6>
                </>
            }
        },
        {
            field: 'next_payment',
            title: 'Next Payment',
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            }
        }
    ]

    const changeHistoryColumns = [
        {
            field: 'date',
            title: 'Date',
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            }
        },
        {
            field: 'previous_amount',
            title: 'Previous Amount (Tk)',
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: (rowData)=>{
                return   <>
                    <h6><strong>{rowData.previous_amount}</strong></h6>
                </>
            }
        },
        {
            field: 'changed_to',
            title: 'Changed To (Tk)',
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: (rowData)=>{
                return   <>
                    <h6><strong>{rowData.changed_to}</strong></h6>
                </>
            }
        },
        {
            field: 'changed_by',
            title: 'Changed By',
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            },
            headerStyle: {
                paddingLeft: '0',
                textAlign: 'left'
            }
        },
        {
            field: 'applicable_to',
            title: 'Applicable To (Tk)',
            emptyValue: ()=>{
                return "-"
            },
            cellStyle: {
                textAlign: 'right'
            },
            headerStyle: {
                paddingRight: '0',
                textAlign: 'right'
            },
            render: (rowData)=>{
                return   <>
                    <span style={{color: '#F18D00'}}>{rowData.applicable_to}</span>
                </>
            }
        }
    ]

    const {
        RETURN_TO_INITIAL_USER_REDUCER_STATE
    } = props;

    useEffect(() => {
        console.log(props)
    }, []);


    const initiateEditProcess = () => {
        history.push({
            pathname: '/users/new-edit-process/',
            userData: userData,
            purpose: 'update'
        })
    }

    const initiateOverdueLimitSetProcess = () => {
        setShowOverdueLimitModal(true)
    }

    const hideOverdueLimitModal = () => {
        setShowOverdueLimitModal(false)
    }

    const renderOverdueLimitModal = () => {
        return <>
            <GenericModal
                size={'lg'}
                showModalHeader={true}
                footer={false}
                hideModal={hideOverdueLimitModal}
                modal={showOverdueLimitModal}
                title={<h1><strong>Edit Overdue Limit</strong></h1>}>
                <div className={"row"}>
                    <div className={"col-md-12"}>
                        <TableContainer>
                            <Table bordered={true} aria-label="spanning table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={tableHeaderLeftAlignedColumnStyling}>Borrower Name</TableCell>
                                        <TableCell style={tableHeaderRightAlignedColumnStyling}>Overdue</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell style={tableLeftAlignedContentStyling}>
                                            <h6><strong>Sabuj Mia</strong></h6>
                                        </TableCell>
                                        <TableCell style={{...tableRightAlignedContentStyling, color: '#CB152B'}}>
                                            <h6><strong>BDT 7,000</strong></h6>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <hr/>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-md-12"}>
                        <Form.Group>
                            <div>
                                <Form.Label>Due Limit (Tk)</Form.Label>
                            </div>
                            <Stack direction={'row'}
                                   justifyContent={'flex-start'}
                                   alignItems={'flex-start'} spacing={2}>
                                <SolInput
                                    type={"number"}
                                    name={"due_limit"}
                                    step={"1"}
                                    min={"1"}
                                    max={"10000"}
                                    placeholder={"Type due limit..."}
                                    autoComplete={"off"}
                                    ref={register()}
                                />
                                <Button variant={"warning"} size={'sm'} type="submit" disabled={false}>
                                    Change
                                </Button>
                            </Stack>
                            {errors.due_limit && <div className="text-danger">
                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.due_limit.message}</div>}
                        </Form.Group>
                        <hr/>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-md-12"}>
                        <strong>Applicable to</strong>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-md-12"}>
                        <DataTable
                            language={language}
                            noDataAvailableMessageInEnglish={'No smart batteries available'}
                            columns={applicableSmartBatteriesColumns}
                            data={applicableSmartBatteries}
                            showToolbar={false}
                            asyncPagination={false}
                            pagination={true}
                            page={5}
                            selection={true}
                            showSelectAllCheckbox={true}
                        />
                    </div>
                </div>
                <div className={"row mt-5"}>
                    <div className={"col-md-12"}>
                        <strong>Change History</strong>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-md-12"}>
                        <DataTable
                            language={language}
                            noDataAvailableMessageInEnglish={'No change logs available'}
                            columns={changeHistoryColumns}
                            data={changeHistories}
                            showToolbar={false}
                            asyncPagination={false}
                            pagination={true}
                            page={5}
                        />
                    </div>
                </div>
            </GenericModal>
        </>
    }

    const initiateResetPasswordProcess = () => {
        setShowResetPasswordModal(true)
    }

    const hideResetPasswordProcessModal = () => {
        setShowResetPasswordModal(false)
    }

    const renderResetPasswordModal = () => {
        return <>
            <GenericModal
                size={'md'}
                showModalHeader={true}
                footer={false}
                hideModal={hideResetPasswordProcessModal}
                modal={showResetPasswordModal}
                title={<h1><strong>Reset Password</strong></h1>}>
                <div className={"row"}>
                    <div className={"col-md-12"}>
                        <Form.Group>
                            <div>
                                <Form.Label>Platform</Form.Label>
                            </div>
                            <Controller
                                control={control}
                                name={"platform"}
                                defaultValue={""}
                                rules={{required: "Platform information is required!"}}
                                render={( { onChange, onBlur, value, name, ref },
                                          { invalid, isTouched, isDirty }) => (
                                    <Select
                                        placeholder={'Select a platform'}
                                        classNamePrefix="react-select-sol-style"
                                        maxMenuHeight={200}
                                        value={value}
                                        isClearable={true}
                                        control={control}
                                        inputRef={ref}
                                        options={passwordUpdatePlatforms}
                                        isSearchable={true}
                                        styles={customStylesForSingleSelectField}
                                        onChange={(selected, {action}) => {
                                            onChange(selected)
                                        }}
                                    />
                                )}
                            />
                            {errors.platform && <div className="text-danger">
                                <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.platform.message}</div>}
                        </Form.Group>
                        <hr/>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-md-12"}>
                        <Button variant={"warning"} size={"sm"} type="submit"
                                disabled={false} className={'ml-3 float-right'}>
                            Reset
                        </Button>
                        <Button variant={"outline-dark"} size={"sm"} disabled={false}
                                onClick={hideResetPasswordProcessModal} className={'float-right'}>
                            Cancel
                        </Button>
                    </div>
                </div>
            </GenericModal>
        </>
    }

    const initiateDeactivateProcess = () => {
        setShowConfirmationModal(true)
        setConfirmationModalTitle("Deactivating " + userData.name)
    }

    const initiateDeleteProcess = () => {
        setShowConfirmationModal(true)
        setConfirmationModalTitle("Deleting " + userData.name)
    }

    const hideConfirmationModal = () => {
        setShowConfirmationModal(false)
        setConfirmationModalTitle("")
    }

    const renderConfirmationModal = () => {
        return <>
            <GenericModal
                size={'md'}
                showModalHeader={true}
                footer={false}
                hideModal={hideConfirmationModal}
                modal={showConfirmationModal}
                title={<h1><strong>{confirmationModalTitle}</strong></h1>}>
                <div className={"row"}>
                    <div className={"col-md-12"}>
                        <h4>Are you sure?</h4>
                        <hr/>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-md-12"}>
                        <Button variant={"warning"} size={"sm"} type="submit"
                                disabled={false} className={'ml-3 float-right'}>
                            Yes
                        </Button>
                        <Button variant={"outline-dark"} size={"sm"} disabled={false}
                                onClick={hideConfirmationModal} className={'float-right'}>
                            No
                        </Button>
                    </div>
                </div>
            </GenericModal>
        </>
    }

    return (
        <>
            <DropdownButton title={<span>Settings <SettingsIcon fontSize={"small"}/></span>} variant={"success"}
                            id={"settings-nested-dropdown"} alignRight={false}>
                <Dropdown.Item eventKey="1" size={'sm'}
                               onClick={initiateEditProcess}>
                    <div className={'container d-flex justify-content-between pl-0 pr-0'}>
                        <span>Edit</span>
                    </div>
                </Dropdown.Item>
                <Dropdown.Divider/>
                <Dropdown.Item eventKey="2" size={'sm'}
                               onClick={initiateOverdueLimitSetProcess}>
                    <div className={'container d-flex justify-content-between pl-0 pr-0'}>
                        <span>Overdue Limit</span>
                    </div>
                </Dropdown.Item>
                <Dropdown.Divider/>
                <Dropdown.Item eventKey="3" size={'sm'}
                               onClick={initiateResetPasswordProcess}>
                    <div className={'container d-flex justify-content-between pl-0 pr-0'}>
                        <span>Reset/Change Password</span>
                    </div>
                </Dropdown.Item>
                <Dropdown.Divider/>
                <Dropdown.Item eventKey="4" size={'sm'}
                               onClick={initiateDeactivateProcess}>
                    <div className={'container d-flex justify-content-between pl-0 pr-0'}>
                        <span>Deactivate</span>
                    </div>
                </Dropdown.Item>
                <Dropdown.Divider/>
                <Dropdown.Item eventKey="5" size={'sm'}
                               onClick={initiateDeleteProcess}>
                    <div className={'container d-flex justify-content-between pl-0 pr-0'}>
                        <span>Delete</span>
                    </div>
                </Dropdown.Item>
            </DropdownButton>

            {renderOverdueLimitModal()}
            {renderResetPasswordModal()}
            {renderConfirmationModal()}
        </>
    )
}

UserSettings.propTypes = {}

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations
    }
}

export default connect(mapStateToProps, { ...actions})(UserSettings);